var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DEFAULT_LOGIN_VOICE_OPTIONS, MicrophoneAccessStatus, PRESENCE_SYNC, SYSTEM_ROLES, TPW_REQUESTED_PAGE_SIZE, USER_ROLES, WEB_RTC_STATUS } from "@/app/components/constants";
import { UserRoles } from "@/app/utils/DesktopLayoutUtils";
import { logger, multipleExist } from "@/app/utils/Utils";
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { SERVICE, getTrackingIdFromErrorObject } from "@agentx/agentx-services";
import { action, computed, observable } from "mobx";
export class ModuleAgent {
    constructor() {
        var _a, _b, _c, _d;
        this.dnNumber = "";
        this.teamName = "";
        this.profileType = "";
        this.enterpriseId = "";
        this.agentName = "";
        this.agentId = "";
        this.agentEmailId = "";
        this.agentProfileID = "";
        this.isOutboundEnabledForTenant = false;
        this.isOutboundEnabledForAgent = false;
        this.isAdhocDialingEnabled = false;
        this.isAgentAvailableAfterOutdial = false;
        this.isCampaignManagementEnabled = false;
        this.campaignManagerAdditionalInfo = "";
        this.acqueonApiUrl = "";
        this.acqueonConsoleUrl = "";
        this.siteId = "";
        this.orgId = "";
        this.usesOtherDN = false;
        this.isExtension = false;
        this.isEndCallEnabled = false;
        this.isEndConsultEnabled = true;
        this.allowConsultToQueue = false;
        this.agentDbId = "";
        this.lcmUrl = "";
        this.teamId = ""; // This is "dbid" attribute value of teams response.
        this.teamUniqueId = ""; // This is "id" attribute value of teams response.
        this.dialPlan = {};
        this.outDialEp = "";
        this.channels = {
            voiceCount: 0,
            chatCount: 0,
            emailCount: 0,
            socialCount: 0
        };
        this.subStatus = "";
        this.subStatusChangeTimestamp = new Date();
        this.idleStatusTimestamp = new Date();
        this.idleCodes = [];
        this.agentStateIdleCodes = null;
        this.agentDefaultWrapupCode = {
            id: "",
            name: ""
        };
        this.agentWrapUpCodes = {
            data: [],
            meta: {
                links: {
                    first: "",
                    last: "",
                    next: "",
                    self: ""
                },
                orgid: "",
                page: 0,
                pageSize: 0,
                totalPages: 0,
                totalRecords: 0
            }
        };
        this.wrapUpData = {
            wrapUpProps: {
                autoWrapup: false,
                autoWrapupInterval: 0,
                lastAgentRoute: false,
                wrapUpReasonList: [],
                interactionId: "",
                allowCancelAutoWrapup: false
            }
        };
        this.outDialAni = {
            initialFetchCompleted: false,
            data: []
        };
        // used to store latest selected ANI, which is to be accessed in widgets
        this.selectedOutdialANI = {
            id: "",
            name: ""
        };
        this.addressBook = {
            initialFetchCompleted: false,
            data: [],
            errorObj: {}
        };
        this.cadVariables = [];
        this.callVariablesSuppressed = [];
        this.userSelectedRole = "";
        this.isMonitoringEnabled = false;
        this.isMidCallMonitoringEnabled = false;
        this.isBargeInEnabled = false;
        this.isManagedTeamsEnabled = false;
        this.isSendMessageEnabled = false;
        this.isAgentStateChangeEnabled = false;
        this.isManagedQueuesEnabled = false;
        this.userRole = [];
        this.organizationId = "";
        this.meUserId = "";
        this.role = "";
        this.preferredSupervisorTeamId = "";
        this.defaultDn = "";
        this.isDefaultDnSet = false;
        this.extensionNumber = "";
        this.dialNumberValueForNonUS = "";
        this.isInternational = false;
        this.internationalPhoneNumber = "";
        this.countryCallingCode = "";
        this.countryCodeValue = "";
        this.isPreferenceSaved = false;
        this.isRememberMyCredentials = false;
        this.isRecordingManagementEnabled = false;
        this.lostConnectionRecoveryTimeout = 120000;
        this.managedTeams = [];
        this.managedQueues = [];
        this.isTimeoutDesktopInactivityEnabled = false;
        this.isAnalyzerEnabled = false;
        this.tenantTimezone = "";
        this.changedByName = null;
        this.webRtcStatus = WEB_RTC_STATUS.WEB_RTC_REGISTERING;
        this.presenceServiceStatus = {
            serviceStatus: "",
            trackingId: ""
        };
        this.lastStateChangeByPresence = false;
        this.isMaskSensitiveDataOrgSettingEnabled = false;
        this.idleCodesAccess = "";
        this.selectedChannelList = JSON.parse(sessionStorage.getItem("selected-channel-list") || "[]");
        this.selectedTeamList = JSON.parse(sessionStorage.getItem("selected-team-list") || "[]");
        this.selectedQueueList = JSON.parse(sessionStorage.getItem("selected-queue-list") || "[]");
        this.isManagedTeamLoading = true;
        this.isManagedQueueLoading = true;
        this.isManagedTeamsError = false;
        this.isManagedQueuesError = false;
        this.organizationIdleCodes = {
            isFetchingSuccess: false,
            data: []
        };
        this.selectedInputDeviceId = "";
        this.selectedOutputDeviceId = "";
        this.dtmfValue = "";
        this.selectedOutputVolume = ((_b = (_a = JSON.parse(localStorage.getItem("desktop-speaker-mic-sound-settings") || "{}")) === null || _a === void 0 ? void 0 : _a.speaker) === null || _b === void 0 ? void 0 : _b.volume) || 0.8;
        this.speakerMute = ((_d = (_c = JSON.parse(localStorage.getItem("desktop-speaker-mic-sound-settings") || "{}")) === null || _c === void 0 ? void 0 : _c.speaker) === null || _d === void 0 ? void 0 : _d.mute) || false;
        this.loginVoiceOptions = [];
        this.deviceType = null;
        this.mediaPermission = MicrophoneAccessStatus.denied;
        this.isEmergencyModalAlreadyDisplayed = false;
        this.epDnMapping = {
            initialFetchCompleted: false,
            data: [],
            errorObj: {}
        };
        this.displayMicrosoftUserDetails = false;
        this.displayWebexUserDetails = false;
        this.stateSynchronization = false;
        this.collabToolInSync = "";
        this.signOutByUi = false;
        this.signOutByProfileChange = false;
        this.fetchDefaultIdleCodes = (orgId) => __awaiter(this, void 0, void 0, function* () {
            try {
                const params = { orgId };
                const response = yield SERVICE.abs.fetchDefaultAuxCodes(params);
                logger.info(`event=fetchDefaultAuxCodes : Successfully fetched ${response.length} default aux codes`);
                return response[0];
            }
            catch (error) {
                logger.error(`event=fetchDefaultIdleCodes | Tracking ID: ${getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : ""} | Fetching default aux code is failed : ${error}`, error);
                throw error;
            }
        });
    }
    get collaboration() {
        return {
            collabToolInSync: this.collabToolInSync,
            displayUserDetails: this.displayMicrosoftUserDetails || this.displayWebexUserDetails,
            displayMicrosoftUserDetails: this.displayMicrosoftUserDetails,
            displayWebexUserDetails: this.displayWebexUserDetails,
            stateSynchronization: this.stateSynchronization
        };
    }
    get analyzerInteractionFilter() {
        return JSON.stringify({
            channelType: this.selectedChannelList,
            teamName: this.selectedTeamList,
            queueName: this.selectedQueueList
        });
    }
    get getAgentStateProps() {
        return {
            agentStateOptions: this.idleCodes,
            agentStateIdleCodes: this.agentStateIdleCodes,
            idleTimeStamp: this.idleStatusTimestamp,
            timerTimeStamp: this.subStatusChangeTimestamp,
            defaultState: this.subStatus,
            engagedState: STORE.agentContact.getEngagedStatus,
            isStateChangedByPresenceSync: this.lastStateChangeByPresence,
            stateChangedByName: this.changedByName,
            isStateChangeBySupervisorEnabled: STORE.app.featureFlags.isStateChangeBySupervisorEnabled,
            currentUserId: STORE.agent.agentId,
            isDesktopAuxCodesInfiniteScrollEnabled: STORE.app.featureFlags.isDesktopAuxCodesInfiniteScrollEnabled,
            idleCodesAccess: this.idleCodesAccess
        };
    }
    get getAgentWrapUpCodeProps() {
        return {
            agentWrapUpCodes: this.agentWrapUpCodes,
            agentDefaultWrapupCode: this.agentDefaultWrapupCode
        };
    }
    get getEpDnMapping() {
        return this.epDnMapping;
    }
    get getAddressBook() {
        return {
            initialFetchCompleted: this.addressBook.initialFetchCompleted,
            errorObj: JSON.parse(JSON.stringify(this.addressBook.errorObj)),
            data: JSON.parse(JSON.stringify(this.addressBook.data))
        };
    }
    get getAcqueonTeamId() {
        return STORE.app.featureFlags.isDesktopMaerskAcqueonTeamId ? this.teamUniqueId : this.teamId;
    }
    get getOutDialAni() {
        return this.outDialAni;
    }
    get outdialAniList() {
        // Required this get outdialAniList for access on JS SDK
        return this.outDialAni.data;
    }
    get getOutdialRegex() {
        /**
         * TODO: Removing dial plan code as it's not being used now.
         * Any Format may not exist if removed from Portal
         * This feature will be implemented later.
         * Outidal component has internal regex validator for now.
         */
        return "";
    }
    get getUserRole() {
        var _a;
        // Not using constants on return values on purpose.
        if (((_a = this.userRole) === null || _a === void 0 ? void 0 : _a.join("_")) === UserRoles.SUPERVISOR) {
            return "supervisor";
        }
        if (this.userSelectedRole) {
            if (this.userSelectedRole === UserRoles.SUPERVISOR) {
                return "supervisor";
            }
            else if (this.userSelectedRole === UserRoles.SUPERVISOR_AGENT) {
                return "supervisorAgent";
            }
        }
        return "agent";
    }
    get getMCMEnabled() {
        return STORE.app.featureFlags.isMidCallMonitoringEnabled && this.isMidCallMonitoringEnabled;
    }
    get getRecordingManagementEnabled() {
        return (STORE.app.featureFlags.isESDPostInteractionInsightsEnabled &&
            STORE.agent.role !== UserRoles.AGENT &&
            this.isRecordingManagementEnabled);
    }
    get getBargeInEnabled() {
        return STORE.app.featureFlags.isDesktopBargeInEnabled && this.isBargeInEnabled;
    }
    get getMicrophoneAccessDenied() {
        return (STORE.app.featureFlags.isWebRTCEnabled &&
            this.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER &&
            this.mediaPermission === "denied");
    }
    get getMediaDeviceSetting() {
        return {
            mic: {
                ID: this.selectedInputDeviceId,
                volume: 1
            },
            speaker: {
                ID: this.selectedOutputDeviceId,
                mute: this.speakerMute,
                volume: this.selectedOutputVolume
            }
        };
    }
    changeStateWithDefaultIdleObject(idleCode, state, lastStateChangeReason = "") {
        SERVICE.aqm.agent
            .stateChange({ data: { state, auxCodeIdArray: idleCode.id, lastStateChangeReason } })
            .then((data) => {
            if (data.data.subStatus === SERVICE.constants.AgentState.Available) {
                this.updateSubStatus(SERVICE.constants.AgentState.Available, data.data.lastStateChangeTimestamp, data.data.lastIdleCodeChangeTimestamp);
            }
            else {
                this.updateSubStatus(idleCode.name.toString(), data.data.lastStateChangeTimestamp, data.data.lastIdleCodeChangeTimestamp);
            }
        })
            .catch((err) => {
            logger.error(`Agent default state change failed request: ${getTrackingIdFromErrorObject(err)}`, err);
            STORE.session.errorModal.setErrorDetails({
                detail: {
                    message: t("app:rona.errorStateChange"),
                    trackingId: getTrackingIdFromErrorObject(err)
                }
            });
        });
    }
    changeAgentState(state) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (state === SERVICE.constants.AgentState.Available) {
                this.makeStateChange(SERVICE.constants.AgentState.AvailableAuxCodeId, SERVICE.constants.AgentState.Available);
            }
            else {
                if (STORE.app.featureFlags.isDesktopAuxCodesInfiniteScrollEnabled) {
                    // make api call to fetch default aux code
                    const response = yield this.fetchDefaultIdleCodes(STORE.agent.orgId);
                    if (response) {
                        const defaultStateOption = response;
                        this.changeStateWithDefaultIdleObject(defaultStateOption, SERVICE.constants.AgentState.Idle);
                    }
                }
                else {
                    const defaultStateOption = this.idleCodes.filter((option) => option.isDefault);
                    this.makeStateChange((_a = defaultStateOption[0]) === null || _a === void 0 ? void 0 : _a.id.toString(), SERVICE.constants.AgentState.Idle);
                }
            }
        });
    }
    makeStateChange(auxCodeId, state, lastStateChangeReason = "") {
        SERVICE.aqm.agent
            .stateChange({ data: { state, auxCodeIdArray: auxCodeId, lastStateChangeReason } })
            .then((data) => {
            var _a;
            if (data.data.subStatus === SERVICE.constants.AgentState.Available) {
                this.updateSubStatus(SERVICE.constants.AgentState.Available, data.data.lastStateChangeTimestamp, data.data.lastIdleCodeChangeTimestamp);
            }
            else {
                const auxCode = this.idleCodes.filter((option) => option.id === data.data.auxCodeId);
                this.updateSubStatus((_a = auxCode[0]) === null || _a === void 0 ? void 0 : _a.name.toString(), data.data.lastStateChangeTimestamp, data.data.lastIdleCodeChangeTimestamp);
            }
        })
            .catch((err) => {
            logger.error(`Agent state change failed request: ${getTrackingIdFromErrorObject(err)}`, err);
            STORE.session.errorModal.setErrorDetails({
                detail: {
                    message: t("app:rona.errorStateChange"),
                    trackingId: getTrackingIdFromErrorObject(err)
                }
            });
        });
    }
    get getAgentData() {
        return {
            orgId: STORE.agent.orgId,
            teamId: STORE.agent.teamId,
            agentId: STORE.agent.agentId,
            selectedLanguage: STORE.app.language
        };
    }
    get isSupervisorySendMessageEnabled() {
        return STORE.app.webexEnabled && this.isSendMessageEnabled;
    }
    get isStateChangeBySupervisorEnabled() {
        return STORE.app.featureFlags.isStateChangeBySupervisorEnabled && this.isAgentStateChangeEnabled;
    }
    get tpwConfigs() {
        return {
            gridId: STORE.routing.currentRouteName,
            isDarkMode: STORE.app.darkMode,
            webexEnabled: STORE.app.webexEnabled,
            isMCMEnabled: this.getMCMEnabled,
            webexEventRegistered: STORE.app.webexEventRegistered,
            supervisorId: STORE.agent.agentId,
            supervisorName: STORE.agent.agentName,
            isAIDFixedVariablesEnabled: STORE.app.featureFlags.isAIDFixedVariablesEnabled,
            isStateChangeBySupervisorEnabled: this.isStateChangeBySupervisorEnabled,
            supervisorAnalyserId: STORE.agent.agentAnalyzerId,
            supervisorEmail: STORE.agent.agentEmailId,
            role: STORE.agent.userSelectedRole,
            onActiveCall: STORE.agentContact.getIsAgentOnActiveCall,
            isSupervisorySendMessageEnabled: this.isSupervisorySendMessageEnabled,
            isStationLoginSuccess: STORE.app.isStationLoginSuccess,
            isEnvironmentProd: STORE.app.isEnvironmentProd,
            idleCodes: STORE.agent.organizationIdleCodes,
            orgId: STORE.agent.orgId,
            isBargeInEnabled: STORE.app.featureFlags.isDesktopBargeInEnabled,
            voiceChannelCount: STORE.agent.channels.voiceCount,
            isMPCorPersistEnabled: STORE.app.featureFlags.isWxccMultiPartyConfEnabled || STORE.app.featureFlags.isWxccPersistCallEnabled,
            isWxccAgentInteractionEnabled: STORE.app.featureFlags.isWxccAgentInteractionEnabled,
            isShowSignedOutAgentsEnabled: STORE.app.featureFlags.isShowSignedOutAgentsEnabled,
            requestedPageSize: TPW_REQUESTED_PAGE_SIZE
        };
    }
    get acqueonAgentId() {
        var _a;
        return STORE.app.featureFlags.isDesktopMaerskAcqueonTeamId
            ? ((_a = STORE.agent.agentAnalyzerId) !== null && _a !== void 0 ? _a : "")
            : STORE.agent.agentDbId;
    }
    get getIsMaskSensitiveDataEnabled() {
        return STORE.app.featureFlags.isMaskSensitiveDataFFEnabled && STORE.agent.isMaskSensitiveDataOrgSettingEnabled;
    }
    updateStatusAfterRONA(newState) {
        this.changeAgentState(newState);
    }
    updateSubStatus(subStatus, lastStateChangeTimestamp, lastIdleCodeChangeTimestamp) {
        this.subStatus = subStatus;
        this.subStatusChangeTimestamp = new Date(lastStateChangeTimestamp);
        this.idleStatusTimestamp = new Date(lastIdleCodeChangeTimestamp);
    }
    updateStateByPresence(auxCodeId, state) {
        var _a, _b, _c;
        let stateData = state;
        let auxCodeIdData = auxCodeId;
        const auxCode = this.idleCodes.filter((option) => option.id === auxCodeId);
        // available state will not be present in idealcodes hence this check
        if (auxCode.length === 0 && state !== SERVICE.constants.AgentState.Available) {
            // fetch default Idlecode
            const defaultIdleCode = this.idleCodes.filter((option) => option.isDefault);
            // if default idle code is same as current state then do not make state change
            if (this.subStatus === ((_a = defaultIdleCode[0]) === null || _a === void 0 ? void 0 : _a.name.toString())) {
                return;
            }
            stateData =
                ((_b = defaultIdleCode[0]) === null || _b === void 0 ? void 0 : _b.name.toString()) === SERVICE.constants.AgentState.Available
                    ? SERVICE.constants.AgentState.Available
                    : SERVICE.constants.AgentState.Idle;
            auxCodeIdData = (_c = defaultIdleCode[0]) === null || _c === void 0 ? void 0 : _c.id.toString();
            logger.info(`Default auxCodeID ${auxCodeIdData} selected for presence sync`);
        }
        this.makeStateChange(auxCodeIdData, stateData, PRESENCE_SYNC);
    }
    updateLastStateChangeByPresence(flag) {
        this.lastStateChangeByPresence = flag;
    }
    updateTeamName(teamName) {
        this.teamName = teamName; // Update Team name in Agent Profile after change team
    }
    updateTeamId(teamId) {
        this.teamId = teamId;
    }
    updateTeamUniqueId(teamId) {
        this.teamUniqueId = teamId;
    }
    updateChangeByName(supervisorName) {
        this.changedByName = supervisorName;
    }
    updateSelectedAgentRole(selectedRoleName) {
        this.userSelectedRole = selectedRoleName; // Update Role name in Agent Profile after change role
    }
    updateDnNumber(dnNumber) {
        this.dnNumber = dnNumber;
    }
    updateUsesOtherDN(usesOtherDN) {
        this.usesOtherDN = usesOtherDN;
    }
    updateIsExtension(isExtension) {
        this.isExtension = isExtension;
    }
    updateDefaultDN(defaultDn) {
        this.defaultDn = defaultDn;
    }
    updateExtensionNumber(extensionNumber) {
        this.extensionNumber = extensionNumber;
    }
    updateDialNumberValueForNonUS(dialNumberValueForNonUS) {
        this.dialNumberValueForNonUS = dialNumberValueForNonUS;
    }
    updateIsInternational(isInternational) {
        this.isInternational = isInternational;
    }
    updateInternationalPhoneNumber(internationalPhoneNumber) {
        this.internationalPhoneNumber = internationalPhoneNumber;
    }
    updateCountryCallingCode(countryCallingCode) {
        this.countryCallingCode = countryCallingCode;
    }
    updateCountryCodeValue(countryCodeValue) {
        this.countryCodeValue = countryCodeValue;
    }
    updateIsPreferenceSaved(isPrefSaved) {
        this.isPreferenceSaved = isPrefSaved;
    }
    updateIsPreferenceSavedFailed(isPrefSaved) {
        this.isPreferenceSaved = isPrefSaved;
    }
    updateIsRememberMyCredentials(isRememberMyCredentials) {
        this.isRememberMyCredentials = isRememberMyCredentials;
    }
    updateDeviceType(deviceType) {
        this.deviceType = deviceType;
    }
    updateEmergencyModalDisplay(isEmergencyModalAlreadyDisplayed = false) {
        this.isEmergencyModalAlreadyDisplayed = isEmergencyModalAlreadyDisplayed;
    }
    updateSelectedInputDeviceId(selectedInputDeviceId) {
        this.selectedInputDeviceId = selectedInputDeviceId;
    }
    updateSelectedOutputDeviceId(selectedOutputDeviceId) {
        this.selectedOutputDeviceId = selectedOutputDeviceId;
    }
    updateMediaSettings(settings) {
        var _a, _b;
        this.selectedOutputVolume = (_a = settings === null || settings === void 0 ? void 0 : settings.speaker) === null || _a === void 0 ? void 0 : _a.volume;
        this.speakerMute = (_b = settings === null || settings === void 0 ? void 0 : settings.speaker) === null || _b === void 0 ? void 0 : _b.mute;
    }
    updateMediaPermission(status) {
        this.mediaPermission = status;
        STORE.alertBanner.updateAlertBanner({
            message: t("app:notifications.microphoneAccessDenied"),
            display: this.getMicrophoneAccessDenied,
            type: "error",
            closable: false
        });
    }
    updateChannelCapacity(mmProfile) {
        this.channels = {
            voiceCount: mmProfile.telephony.length || 0,
            chatCount: mmProfile.chat.length || 0,
            emailCount: mmProfile.email.length || 0,
            socialCount: mmProfile.social.length || 0
        };
    }
    updateProfileType(profileType) {
        this.profileType = profileType;
    }
    updateAgentId(agentId) {
        this.agentId = agentId;
    }
    updateAgentProfileLogin(detail) {
        const { dn, teamName, subStatus, lastStateChangeTimestamp, lastIdleCodeChangeTimestamp, profileType, mmProfile, teamId, teamUniqueId } = detail;
        this.dnNumber = dn;
        this.agentId = detail.agentId;
        this.teamName = teamName ? teamName : "";
        this.channels = {
            voiceCount: mmProfile.voiceCount,
            chatCount: mmProfile.chatCount,
            emailCount: mmProfile.emailCount,
            socialCount: mmProfile.socialCount
        };
        this.profileType = profileType;
        this.teamId = teamId ? teamId : "";
        this.teamUniqueId = teamUniqueId ? teamUniqueId : "";
        this.usesOtherDN = detail.usesOtherDN;
        this.updateSubStatus(subStatus, lastStateChangeTimestamp, lastIdleCodeChangeTimestamp);
    }
    updateInitialConfiguration(profile) {
        var _a, _b;
        const { agentName, agentMailId, enterpriseId, agentProfileID, wrapUpData, agentWrapUpCodes, agentDefaultWrapUpCode, agentId, privacyShieldVisible, idleCodes, isAdhocDialingEnabled, isOutboundEnabledForAgent, isOutboundEnabledForTenant, isAgentAvailableAfterOutdial, isCampaignManagementEnabled, outDialEp, dialPlan, isEndCallEnabled, isEndConsultEnabled, cadVariables, callVariablesSuppressed, allowConsultToQueue, lcmUrl, agentDbId, campaignManagerAdditionalInfo, urlMappings, siteId, orgId, defaultDn, forceDefaultDnForAgent, preferredSupervisorTeamId, isCallMonitoringEnabled, isMidCallMonitoringEnabled, isBargeInEnabled, isManagedTeamsEnabled, isManagedQueuesEnabled, isSendMessageEnabled, isAgentStateChangeEnabled, isTimeoutDesktopInactivityEnabled, timeoutDesktopInactivityMins, analyserUserId, isAnalyzerEnabled, loginVoiceOptions, tenantTimezone, organizationIdleCodes, isRecordingManagementEnabled, lostConnectionRecoveryTimeout, maskSensitiveData, idleCodesAccess } = profile;
        this.agentName = agentName;
        this.agentId = agentId;
        this.agentEmailId = agentMailId;
        this.enterpriseId = enterpriseId;
        this.wrapUpData = wrapUpData;
        this.agentWrapUpCodes = agentWrapUpCodes;
        this.agentDefaultWrapupCode = agentDefaultWrapUpCode;
        this.cadVariables = cadVariables;
        this.callVariablesSuppressed = callVariablesSuppressed;
        this.agentProfileID = agentProfileID;
        this.agentAnalyzerId = this.agentAnalyzerId ? this.agentAnalyzerId : analyserUserId;
        this.privacyShieldVisible = privacyShieldVisible;
        this.idleCodes = idleCodes;
        this.isAdhocDialingEnabled = isAdhocDialingEnabled;
        this.isOutboundEnabledForAgent = isOutboundEnabledForAgent;
        this.isOutboundEnabledForTenant = isOutboundEnabledForTenant;
        this.isAgentAvailableAfterOutdial = isAgentAvailableAfterOutdial;
        this.isCampaignManagementEnabled = isCampaignManagementEnabled;
        this.isEndCallEnabled = isEndCallEnabled;
        this.isEndConsultEnabled = isEndConsultEnabled;
        this.allowConsultToQueue = allowConsultToQueue;
        this.outDialEp = outDialEp;
        this.dialPlan = dialPlan;
        this.agentDbId = agentDbId;
        this.lcmUrl = lcmUrl;
        this.campaignManagerAdditionalInfo = campaignManagerAdditionalInfo;
        this.acqueonApiUrl = (_a = urlMappings === null || urlMappings === void 0 ? void 0 : urlMappings.acqueonApiUrl) !== null && _a !== void 0 ? _a : "";
        this.acqueonConsoleUrl = (_b = urlMappings === null || urlMappings === void 0 ? void 0 : urlMappings.acqueonConsoleUrl) !== null && _b !== void 0 ? _b : "";
        this.siteId = siteId;
        this.orgId = orgId;
        this.defaultDn = defaultDn;
        this.isDefaultDnSet = (defaultDn === null || defaultDn === void 0 ? void 0 : defaultDn.length) > 0 ? forceDefaultDnForAgent : false;
        this.preferredSupervisorTeamId = preferredSupervisorTeamId;
        this.isMonitoringEnabled = isCallMonitoringEnabled || false;
        this.isMidCallMonitoringEnabled = isMidCallMonitoringEnabled || false;
        this.isBargeInEnabled = isBargeInEnabled || false;
        this.isManagedTeamsEnabled = isManagedTeamsEnabled || false;
        this.isManagedQueuesEnabled = isManagedQueuesEnabled || false;
        this.timeoutDesktopInactivityMins = timeoutDesktopInactivityMins;
        this.isTimeoutDesktopInactivityEnabled = isTimeoutDesktopInactivityEnabled;
        this.isSendMessageEnabled = isSendMessageEnabled || false;
        this.isAgentStateChangeEnabled = isAgentStateChangeEnabled || false;
        this.isAnalyzerEnabled = isAnalyzerEnabled || false;
        this.tenantTimezone = tenantTimezone;
        this.loginVoiceOptions = loginVoiceOptions || DEFAULT_LOGIN_VOICE_OPTIONS;
        this.isRecordingManagementEnabled = isRecordingManagementEnabled || false;
        this.lostConnectionRecoveryTimeout = lostConnectionRecoveryTimeout || this.lostConnectionRecoveryTimeout;
        this.isMaskSensitiveDataOrgSettingEnabled = maskSensitiveData || false;
        this.idleCodesAccess = idleCodesAccess || "ALL";
        if (STORE.app.featureFlags.isCMSConfigMigrated &&
            (!STORE.app.featureFlags.isDesktopMaerskEnhancementEnabled ||
                (STORE.app.featureFlags.isDesktopMaerskEnhancementEnabled && profile.idleCodesAccess === "ALL"))) {
            const orgIdleCodes = STORE.app.featureFlags.isDesktopMaerskEnhancementEnabled
                ? profile.idleCodes
                : organizationIdleCodes;
            // for pure supervisor (ie. only supervisor) aux codes are not fetched by default
            const isFetchingSuccess = STORE.agent.getUserRole !== UserRoles.SUPERVISOR;
            this.updateOrganizationIdleCodes(isFetchingSuccess, orgIdleCodes !== null && orgIdleCodes !== void 0 ? orgIdleCodes : []);
        }
    }
    updateStatusAfterOutdial(e) {
        if (this.subStatus !== SERVICE.constants.AgentState.Available &&
            this.isAgentAvailableAfterOutdial &&
            e.detail.contactDirection === SERVICE.constants.ContactDirection.OUTBOUND) {
            this.changeAgentState(SERVICE.constants.AgentState.Available);
        }
    }
    updateStatusAfterProfileUpdate() {
        this.changeAgentState(SERVICE.constants.AgentState.Idle);
    }
    updateStateToRona() {
        this.updateSubStatus(SERVICE.constants.AgentState.RONA, new Date().getTime(), new Date().getTime());
    }
    updateoutdialAniList(resp) {
        this.outDialAni = {
            initialFetchCompleted: true,
            data: resp.data.data.mockOutdialAniList
        };
    }
    updateSelectedOutdialANI(ani) {
        this.selectedOutdialANI = ani;
    }
    updateoutdialAniListFailed() {
        this.outDialAni = {
            initialFetchCompleted: true,
            data: []
        };
    }
    updateAgentStateIdleCodes(resp) {
        this.agentStateIdleCodes = resp;
    }
    updateAgentWrapUpCodes(resp) {
        this.agentWrapUpCodes = resp;
    }
    updateWrapupList(wrapupReasonList) {
        this.wrapUpData.wrapUpProps.wrapUpReasonList = wrapupReasonList;
    }
    updateAddressbookList(addressBookData) {
        this.addressBook = {
            initialFetchCompleted: true,
            data: addressBookData.speedDials,
            errorObj: {}
        };
    }
    updateAddressbookListFailed(resp) {
        this.addressBook = {
            initialFetchCompleted: true,
            data: [],
            errorObj: resp.error
        };
    }
    updateSelectedChannelList(selectedChannelList) {
        this.selectedChannelList = selectedChannelList;
    }
    updateSelectedTeamList(selectedTeamList) {
        this.selectedTeamList = selectedTeamList;
    }
    updateSelectedQueueList(selectedQueueList) {
        this.selectedQueueList = selectedQueueList;
    }
    updateDtmfValue(dtmfValue) {
        this.dtmfValue = dtmfValue;
    }
    selectState(e) {
        return __awaiter(this, void 0, void 0, function* () {
            this.subStatus = e.detail.agentStatus;
            this.subStatusChangeTimestamp = new Date(e.detail.lastStateChangeTimestamp);
            this.idleStatusTimestamp = new Date(e.detail.lastIdleCodeChangeTimestamp);
            this.changedByName = e.detail.changedByName;
        });
    }
    updateAgentMe(meData) {
        var _a, _b, _c;
        const agentPhoto = (_b = (_a = meData === null || meData === void 0 ? void 0 : meData.photos) === null || _a === void 0 ? void 0 : _a.filter((data) => data.type === "thumbnail")[0]) === null || _b === void 0 ? void 0 : _b.value;
        const userRoles = meData === null || meData === void 0 ? void 0 : meData.roles;
        const organizationId = (_c = meData === null || meData === void 0 ? void 0 : meData.meta) === null || _c === void 0 ? void 0 : _c.organizationID;
        const meUserId = meData === null || meData === void 0 ? void 0 : meData.id;
        let role = "";
        let supervisorRoles = [USER_ROLES.SUPERVISOR];
        if (SERVICE.featureflag.isESDStationLoginFlowEnabled() && SERVICE.featureflag.isSupervisorDesktopEnabled()) {
            supervisorRoles = [USER_ROLES.FULL_ADMIN, USER_ROLES.CCS_ADMIN];
        }
        if (multipleExist(userRoles, supervisorRoles)) {
            role = SYSTEM_ROLES.AGENT;
        }
        else if (userRoles.includes(USER_ROLES.SUPERVISOR)) {
            role = SYSTEM_ROLES.SUPERVISOR;
        }
        else if ((userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes(USER_ROLES.PREMIUM_AGENT)) || (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes(USER_ROLES.STANDARD_AGENT))) {
            role = SYSTEM_ROLES.AGENT;
        }
        this.setUserProfileInfo(role, agentPhoto, organizationId, meUserId);
    }
    setRole(role, meData, orgDetails) {
        var _a, _b;
        const agentPhoto = (_b = (_a = meData === null || meData === void 0 ? void 0 : meData.photos) === null || _a === void 0 ? void 0 : _a.filter((data) => data.type === "thumbnail")[0]) === null || _b === void 0 ? void 0 : _b.value;
        const organizationId = orgDetails.orgId;
        const meUserId = meData === null || meData === void 0 ? void 0 : meData.id;
        this.setUserProfileInfo(role, agentPhoto, organizationId, meUserId);
    }
    setUserProfileInfo(role, agentPhoto, organizationId, meUserId) {
        this.role = role;
        this.agentPhoto = agentPhoto;
        this.organizationId = organizationId;
        this.meUserId = meUserId;
    }
    updateAgentAnalyzerId(userId) {
        this.agentAnalyzerId = userId;
    }
    updateUserRole(userRole) {
        this.userRole = userRole;
    }
    updateWebRtcStatus(status) {
        this.webRtcStatus = status;
    }
    updateEpDnList(data) {
        this.epDnMapping = {
            initialFetchCompleted: true,
            data,
            errorObj: {}
        };
    }
    updatetrackingIdforEpList(errorObj) {
        this.epDnMapping = {
            initialFetchCompleted: true,
            data: [],
            errorObj
        };
    }
    updateManagedTeams(managedTeamsData) {
        this.managedTeams = [...managedTeamsData].sort((t1, t2) => {
            return t1.teamName.localeCompare(t2.teamName);
        });
        this.updateIsManagedTeamError(false);
    }
    updateManagedQueues(managedQueuesData) {
        this.managedQueues = [...managedQueuesData].sort((t1, t2) => {
            return t1.queueName.localeCompare(t2.queueName);
        });
        this.updateIsManagedQueueError(false);
    }
    updateIsManagedTeamLoading(status) {
        this.isManagedTeamLoading = status;
    }
    updateIsManagedQueueLoading(status) {
        this.isManagedQueueLoading = status;
    }
    updateIsManagedTeamEnabled(status) {
        this.isManagedTeamsEnabled = status;
    }
    updateIsManagedQueueEnabled(status) {
        this.isManagedQueuesEnabled = status;
    }
    updateIsManagedTeamError(status) {
        this.isManagedTeamsError = status;
    }
    updateIsManagedQueueError(status) {
        this.isManagedQueuesError = status;
    }
    updateOrganizationIdleCodes(isFetchingSuccess, data) {
        this.organizationIdleCodes = {
            isFetchingSuccess,
            data
        };
    }
    updatePresenceServiceStatus(serviceStatus, trackingId) {
        this.presenceServiceStatus = {
            serviceStatus,
            trackingId
        };
    }
    updateDisplayMicrosoftUserDetailsEnabled(status) {
        this.displayMicrosoftUserDetails = status;
    }
    updateDisplayWebexUserDetailsEnabled(status) {
        this.displayWebexUserDetails = status;
    }
    updateStateSyncEnabled(status) {
        this.stateSynchronization = status;
    }
    updateCollabToolInSync(toolName) {
        this.collabToolInSync = toolName;
    }
    updateSignOutByUi(isSignedOut) {
        this.signOutByUi = isSignedOut;
    }
    updateSignOutByChangeProfile(isSignedOut) {
        this.signOutByProfileChange = isSignedOut;
    }
    updateIdleCodesList(idleCodesList) {
        this.idleCodes = idleCodesList;
    }
}
__decorate([
    observable
], ModuleAgent.prototype, "dnNumber", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "teamName", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "profileType", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "enterpriseId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "privacyShieldVisible", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentName", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentEmailId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentProfileID", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isOutboundEnabledForTenant", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isOutboundEnabledForAgent", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isAdhocDialingEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isAgentAvailableAfterOutdial", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isCampaignManagementEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "campaignManagerAdditionalInfo", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "acqueonApiUrl", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "acqueonConsoleUrl", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "siteId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "orgId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "usesOtherDN", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isExtension", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isEndCallEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isEndConsultEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "allowConsultToQueue", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentDbId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "lcmUrl", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "teamId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "teamUniqueId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "dialPlan", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "outDialEp", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentPhoto", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "channels", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "subStatus", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "subStatusChangeTimestamp", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "idleStatusTimestamp", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "idleCodes", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentStateIdleCodes", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentDefaultWrapupCode", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentWrapUpCodes", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "wrapUpData", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "outDialAni", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "selectedOutdialANI", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "addressBook", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "cadVariables", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "callVariablesSuppressed", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "agentAnalyzerId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "userSelectedRole", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isMonitoringEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isMidCallMonitoringEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isBargeInEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isManagedTeamsEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isSendMessageEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isAgentStateChangeEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isManagedQueuesEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "userRole", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "organizationId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "meUserId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "role", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "preferredSupervisorTeamId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "defaultDn", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isDefaultDnSet", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "extensionNumber", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "dialNumberValueForNonUS", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isInternational", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "internationalPhoneNumber", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "countryCallingCode", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "countryCodeValue", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isPreferenceSaved", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isRememberMyCredentials", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isRecordingManagementEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "lostConnectionRecoveryTimeout", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "managedTeams", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "managedQueues", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isTimeoutDesktopInactivityEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "timeoutDesktopInactivityMins", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isAnalyzerEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "tenantTimezone", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "changedByName", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "webRtcStatus", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "presenceServiceStatus", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "lastStateChangeByPresence", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isMaskSensitiveDataOrgSettingEnabled", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "idleCodesAccess", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "selectedChannelList", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "selectedTeamList", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "selectedQueueList", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isManagedTeamLoading", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isManagedQueueLoading", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isManagedTeamsError", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isManagedQueuesError", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "organizationIdleCodes", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "selectedInputDeviceId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "selectedOutputDeviceId", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "dtmfValue", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "selectedOutputVolume", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "speakerMute", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "loginVoiceOptions", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "deviceType", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "mediaPermission", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "isEmergencyModalAlreadyDisplayed", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "epDnMapping", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "displayMicrosoftUserDetails", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "displayWebexUserDetails", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "stateSynchronization", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "collabToolInSync", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "signOutByUi", void 0);
__decorate([
    observable
], ModuleAgent.prototype, "signOutByProfileChange", void 0);
__decorate([
    computed
], ModuleAgent.prototype, "collaboration", null);
__decorate([
    computed
], ModuleAgent.prototype, "analyzerInteractionFilter", null);
__decorate([
    computed
], ModuleAgent.prototype, "getAgentStateProps", null);
__decorate([
    computed
], ModuleAgent.prototype, "getAgentWrapUpCodeProps", null);
__decorate([
    computed
], ModuleAgent.prototype, "getEpDnMapping", null);
__decorate([
    computed
], ModuleAgent.prototype, "getAddressBook", null);
__decorate([
    computed
], ModuleAgent.prototype, "getAcqueonTeamId", null);
__decorate([
    computed
], ModuleAgent.prototype, "getOutDialAni", null);
__decorate([
    computed
], ModuleAgent.prototype, "outdialAniList", null);
__decorate([
    computed
], ModuleAgent.prototype, "getOutdialRegex", null);
__decorate([
    computed
], ModuleAgent.prototype, "getUserRole", null);
__decorate([
    computed
], ModuleAgent.prototype, "getMCMEnabled", null);
__decorate([
    computed
], ModuleAgent.prototype, "getRecordingManagementEnabled", null);
__decorate([
    computed
], ModuleAgent.prototype, "getBargeInEnabled", null);
__decorate([
    computed
], ModuleAgent.prototype, "getMicrophoneAccessDenied", null);
__decorate([
    computed
], ModuleAgent.prototype, "getMediaDeviceSetting", null);
__decorate([
    computed
], ModuleAgent.prototype, "getAgentData", null);
__decorate([
    computed
], ModuleAgent.prototype, "isSupervisorySendMessageEnabled", null);
__decorate([
    computed
], ModuleAgent.prototype, "isStateChangeBySupervisorEnabled", null);
__decorate([
    computed
], ModuleAgent.prototype, "tpwConfigs", null);
__decorate([
    computed
], ModuleAgent.prototype, "acqueonAgentId", null);
__decorate([
    computed
], ModuleAgent.prototype, "getIsMaskSensitiveDataEnabled", null);
__decorate([
    action
], ModuleAgent.prototype, "updateStatusAfterRONA", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSubStatus", null);
__decorate([
    action
], ModuleAgent.prototype, "updateStateByPresence", null);
__decorate([
    action
], ModuleAgent.prototype, "updateLastStateChangeByPresence", null);
__decorate([
    action
], ModuleAgent.prototype, "updateTeamName", null);
__decorate([
    action
], ModuleAgent.prototype, "updateTeamId", null);
__decorate([
    action
], ModuleAgent.prototype, "updateTeamUniqueId", null);
__decorate([
    action
], ModuleAgent.prototype, "updateChangeByName", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSelectedAgentRole", null);
__decorate([
    action
], ModuleAgent.prototype, "updateDnNumber", null);
__decorate([
    action
], ModuleAgent.prototype, "updateUsesOtherDN", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsExtension", null);
__decorate([
    action
], ModuleAgent.prototype, "updateDefaultDN", null);
__decorate([
    action
], ModuleAgent.prototype, "updateExtensionNumber", null);
__decorate([
    action
], ModuleAgent.prototype, "updateDialNumberValueForNonUS", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsInternational", null);
__decorate([
    action
], ModuleAgent.prototype, "updateInternationalPhoneNumber", null);
__decorate([
    action
], ModuleAgent.prototype, "updateCountryCallingCode", null);
__decorate([
    action
], ModuleAgent.prototype, "updateCountryCodeValue", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsPreferenceSaved", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsPreferenceSavedFailed", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsRememberMyCredentials", null);
__decorate([
    action
], ModuleAgent.prototype, "updateDeviceType", null);
__decorate([
    action
], ModuleAgent.prototype, "updateEmergencyModalDisplay", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSelectedInputDeviceId", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSelectedOutputDeviceId", null);
__decorate([
    action
], ModuleAgent.prototype, "updateMediaSettings", null);
__decorate([
    action
], ModuleAgent.prototype, "updateMediaPermission", null);
__decorate([
    action
], ModuleAgent.prototype, "updateChannelCapacity", null);
__decorate([
    action
], ModuleAgent.prototype, "updateProfileType", null);
__decorate([
    action
], ModuleAgent.prototype, "updateAgentId", null);
__decorate([
    action
], ModuleAgent.prototype, "updateAgentProfileLogin", null);
__decorate([
    action
], ModuleAgent.prototype, "updateInitialConfiguration", null);
__decorate([
    action
], ModuleAgent.prototype, "updateStatusAfterOutdial", null);
__decorate([
    action
], ModuleAgent.prototype, "updateStatusAfterProfileUpdate", null);
__decorate([
    action
], ModuleAgent.prototype, "updateStateToRona", null);
__decorate([
    action
], ModuleAgent.prototype, "updateoutdialAniList", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSelectedOutdialANI", null);
__decorate([
    action
], ModuleAgent.prototype, "updateoutdialAniListFailed", null);
__decorate([
    action
], ModuleAgent.prototype, "updateAgentStateIdleCodes", null);
__decorate([
    action
], ModuleAgent.prototype, "updateAgentWrapUpCodes", null);
__decorate([
    action
], ModuleAgent.prototype, "updateWrapupList", null);
__decorate([
    action
], ModuleAgent.prototype, "updateAddressbookList", null);
__decorate([
    action
], ModuleAgent.prototype, "updateAddressbookListFailed", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSelectedChannelList", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSelectedTeamList", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSelectedQueueList", null);
__decorate([
    action
], ModuleAgent.prototype, "updateDtmfValue", null);
__decorate([
    action
], ModuleAgent.prototype, "selectState", null);
__decorate([
    action
], ModuleAgent.prototype, "updateAgentMe", null);
__decorate([
    action
], ModuleAgent.prototype, "setRole", null);
__decorate([
    action
], ModuleAgent.prototype, "setUserProfileInfo", null);
__decorate([
    action
], ModuleAgent.prototype, "updateAgentAnalyzerId", null);
__decorate([
    action
], ModuleAgent.prototype, "updateUserRole", null);
__decorate([
    action
], ModuleAgent.prototype, "updateWebRtcStatus", null);
__decorate([
    action
], ModuleAgent.prototype, "updateEpDnList", null);
__decorate([
    action
], ModuleAgent.prototype, "updatetrackingIdforEpList", null);
__decorate([
    action
], ModuleAgent.prototype, "updateManagedTeams", null);
__decorate([
    action
], ModuleAgent.prototype, "updateManagedQueues", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsManagedTeamLoading", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsManagedQueueLoading", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsManagedTeamEnabled", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsManagedQueueEnabled", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsManagedTeamError", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIsManagedQueueError", null);
__decorate([
    action
], ModuleAgent.prototype, "updateOrganizationIdleCodes", null);
__decorate([
    action
], ModuleAgent.prototype, "updatePresenceServiceStatus", null);
__decorate([
    action
], ModuleAgent.prototype, "updateDisplayMicrosoftUserDetailsEnabled", null);
__decorate([
    action
], ModuleAgent.prototype, "updateDisplayWebexUserDetailsEnabled", null);
__decorate([
    action
], ModuleAgent.prototype, "updateStateSyncEnabled", null);
__decorate([
    action
], ModuleAgent.prototype, "updateCollabToolInSync", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSignOutByUi", null);
__decorate([
    action
], ModuleAgent.prototype, "updateSignOutByChangeProfile", null);
__decorate([
    action
], ModuleAgent.prototype, "updateIdleCodesList", null);
export const agent = new ModuleAgent();
// JS DOC Comments - Type Definitions
/**
 * @typedef  {Object} agent
 * @summary $STORE.agent
 * @property {String} dnNumber="" Dial Number
 * @property {String} teamName="" Name of the team.
 * @property {String} profileType="" Profile Type
 * @property {String} enterpriseId="" Unique Database Identifier of the tenant.
 * @property {String} agentName="" Name of the agent.
 * @property {String} agentId="" Unique identifier of the agent.
 * @property {String} agentEmailId="" Email ID of the agent.
 * @property {Boolean} isOutboundEnabledForTenant=false Determines whether the outbound feature is enabled for the tenant.
 * @property {Boolean} isOutboundEnabledForAgent=false Determines whether the outbound feature is enabled for the agent.
 * @property {Boolean} isAdhocDialingEnabled=false Determines whether agent can use numbers other than the ones mentioned in address book for the outdial.
 * @property {Boolean} isAgentAvailableAfterOutdial=false Determines whether the agent is available after the outdial.
 * @property {Boolean} isCampaignManagementEnabled=false Determines whether the campaign management enabled.
 * @property {String} campaignManagerAdditionalInfo="" Additional information for the campaign management.
 * @property {String} siteId="" Unique Identifier for the site.
 * @property {String} orgId="" Unique Identifier for the organization.
 * @property {Boolean} usesOtherDN=false Other Dial Number (Non-US Format).
 * @property {Boolean} isEndCallEnabled=false Determines whether the end call feature is enabled.
 * @property {Boolean} isEndConsultEnabled=true Determines whether the end consult call feature is enabled.
 * @property {Boolean} allowConsultToQueue=false Determines whether consult can go to queue.
 * @property {String} teamId="" Unique identifier of the team.
 * @property {String} outDialEp="" Outdial Entry Point
 * @property {String} changedByName="" supervisor who changed agent state.
 * @property {(String|Undefined)} agentPhoto URL for the photo of the agent in JPG format.
 * @property {Object} channels Channels: Includes voice, chat, email, and social
 * @property {Number} channels.voiceCount=0 Channel - Voice Count
 * @property {Number} channels.chatCount=0 Channel - Chat Count
 * @property {Number} channels.emailCount=0 Channel - Email Count
 * @property {Number} channels.socialCount=0 Channel - Social Count
 * @property {Service_Aqm_Agent_StateChange} subStatus="" Agent availability status.
 * The possible values for sub status can be Available, Idle, or RONA [Service.Aqm.Agent.StateChange["state"]]
 * @property {Date} subStatusChangeTimestamp Timestamp for change in the agent state.
 * @property {Date} idleStatusTimestamp Timestamp for idle status.
 * @property {Service_Aqm_Configs_Entity} idleCodes=[]] The idle reason codes indicating that the agent is not ready to accept any routed requests.
 * Example: Idle, Coffee break, Meeting, Tea. [Service.Aqm.Configs.Entity]
 * @property {Service_Aqm_Configs_WrapupData} wrapUpData Wrap up data. [Service.Aqm.Configs.WrapupData]
 * @property {Service_Aqm_Agent_OutdialAni} outDialAni.data=[]] Outdial ANI List. [Service.Aqm.Agent.OutdialAni]
 * @property {String} userSelectedRole="" Name of Selected UserRole
 * @property {String} isMonitoringEnabled=false Monitoring flag for user
 * @property {String} isMidCallMonitoringEnabled=false Mid call Monitoring flag for user
 * @property {boolean} isTimeoutDesktopInactivityEnabled = false determines whether the inactivity timeout feature is enabled.
 * @property {number} timeoutDesktopInactivityMins = 0 determines the timeout interval in minutes for inactivity
 * @property {boolean} isAnalyzerEnabled = false determines whether the supervisor has access to analyzer or not.
 * @property {boolean} isManagedQueuesError false determines the no error. If we have a error then will store the trackingId.
 * @property {boolean} isManagedTeamsError false determines the no error. If we have a error then will store the trackingId.
 * @property {String[]} loginVoiceOptions= DEFAULT_LOGIN_VOICE_OPTIONS Agent Login Voice options. AGENT_DN | EXTENSION | BROWSER
 * @property {String} deviceType = AGENT_DN Agent Device Selection
 * @property {String} mediaPermission = "denied" Microphone Access Status "granted" | "denied" | "prompt"
 * @property {boolean} isRecordingManagementEnabled=false Manage Recordings flag for user
 */
