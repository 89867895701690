var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import { MicrophoneAccessStatus } from "../components/constants";
import { logger } from "./Utils";
export const handlePermissionUpdate = () => __awaiter(void 0, void 0, void 0, function* () {
    navigator.permissions
        .query({ name: "microphone" })
        .then((permissionStatus) => {
        logger.info(`[WebRtc] microphone permission state is ${permissionStatus.state}`);
        STORE.agent.updateMediaPermission(permissionStatus.state);
        // listen microphone permission change
        permissionStatus.onchange = () => {
            logger.info(`[WebRtc] microphone updated permission state is ${permissionStatus.state}`);
            STORE.agent.updateMediaPermission(permissionStatus.state);
        };
    })
        .catch((err) => {
        logger.error("[WebRtc] microphone permission denied", err);
        STORE.agent.updateMediaPermission(MicrophoneAccessStatus.denied);
    });
});
export const checkNeedsWebexRegister = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    if (!((_a = SERVICE.webex) === null || _a === void 0 ? void 0 : _a.webexInitializedStatus())) {
        yield ((_b = SERVICE.webex) === null || _b === void 0 ? void 0 : _b.registerWebex(STORE.app.isEnvironmentProd));
    }
});
export const registerWebCalling = (domainUrl) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        handlePermissionUpdate();
        const mediaStream = yield navigator.mediaDevices.getUserMedia({ audio: true });
        if (mediaStream) {
            logger.info("[WebRtc] : Microphone access granted!");
            STORE.agent.updateMediaPermission(MicrophoneAccessStatus.granted);
            SERVICE.webCalling.registerWebCalling(domainUrl);
            mediaStream.getTracks().forEach((track) => track.stop());
        }
        else {
            STORE.agent.updateMediaPermission(MicrophoneAccessStatus.denied);
        }
    }
    catch (error) {
        logger.error("[WebRtc] : Microphone access denied!", error);
        STORE.agent.updateMediaPermission(MicrophoneAccessStatus.denied);
    }
});
