import { STORE } from "@/store";
import { ToasterNotificationItemContent } from "@uuip/unified-ui-platform-common-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { InboundRequestType } from "./MessageHandler";
class AiAssistantMessageProcessor {
    constructor() {
        this.aiAssistantNotifications = [];
        this.wellnessBreakNotifications = [];
    }
    process(processParams) {
        switch (processParams.inboundRequestType) {
            case InboundRequestType.ShowCallDropAiNotification:
                if (processParams.notificationLinkHandlerCallback) {
                    this.showCallDropAiNotification(processParams.notificationLinkHandlerCallback);
                }
                break;
            case InboundRequestType.RemoveCallDropAiNotifications:
                this.removeCallDropAiNotifications();
                break;
            case InboundRequestType.ShowWellnessBreakNotification:
                if (processParams.notificationAcceptActionCallback &&
                    processParams.notificationRejectActionCallback &&
                    processParams.notificationDismissActionCallback) {
                    this.showWellnessBreakNotification(processParams.notificationAcceptActionCallback, processParams.notificationRejectActionCallback, processParams.notificationDismissActionCallback);
                }
                break;
            case InboundRequestType.RemoveWellnessBreakNotifications:
                this.removeWellnessBreakNotifications(Notifications.ItemMeta.DeactivateEventReason.UserNeutral);
                break;
            default:
                break;
        }
    }
    showWellnessBreakNotification(acceptActionCallback, rejectActionCallback, dissmissActionCallback) {
        var _a;
        const wellnessBreakNotificationRaw = {
            type: Notifications.ItemMeta.Type.Info,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: "Ready to pause and recharge?",
            data: new ToasterNotificationItemContent.DataController({
                text: "Hey there, you're on a roll. How about a wellness break? This break is pre-approved by your organization for your well-being. You deserve it.",
                actions: [
                    {
                        label: "Later",
                        handler: (n) => {
                            rejectActionCallback();
                            this.removeWellnessBreakNotifications(Notifications.ItemMeta.DeactivateEventReason.UserNegative);
                        },
                        variant: "secondary"
                    },
                    {
                        label: "Take a break",
                        handler: (n) => {
                            acceptActionCallback();
                            this.removeWellnessBreakNotifications(Notifications.ItemMeta.DeactivateEventReason.UserPositive);
                        },
                        variant: "primary"
                    }
                ],
                dismissHandler: (n) => {
                    dissmissActionCallback();
                }
            })
        };
        const newWellnessBreakNotification = (_a = STORE.generalNotifications
            .fireNotificationEvent(wellnessBreakNotificationRaw)) === null || _a === void 0 ? void 0 : _a.at(0);
        if (newWellnessBreakNotification) {
            this.wellnessBreakNotifications = [...this.wellnessBreakNotifications, newWellnessBreakNotification];
        }
    }
    removeWellnessBreakNotifications(removeReason) {
        this.wellnessBreakNotifications.forEach((notification) => {
            notification.deactivate(removeReason);
        });
        this.wellnessBreakNotifications = [];
    }
    showCallDropAiNotification(openAiAssistantCallback) {
        var _a;
        const aiAssistantNotificationRaw = {
            type: Notifications.ItemMeta.Type.Info,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: "Call drop summary available",
            data: new ToasterNotificationItemContent.DataController({
                text: "Customer's last call ended abruptly",
                linkName: "View summary",
                linkHandler: (n) => {
                    openAiAssistantCallback();
                }
            })
        };
        const newAiAssistantNotification = (_a = STORE.generalNotifications
            .fireNotificationEvent(aiAssistantNotificationRaw)) === null || _a === void 0 ? void 0 : _a.at(0);
        if (newAiAssistantNotification) {
            this.aiAssistantNotifications = [...this.aiAssistantNotifications, newAiAssistantNotification];
        }
    }
    removeCallDropAiNotifications() {
        this.aiAssistantNotifications.forEach((notification) => {
            notification.deactivate(Notifications.ItemMeta.DeactivateEventReason.UserNeutral);
        });
        this.aiAssistantNotifications = [];
    }
}
export default AiAssistantMessageProcessor;
