var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AGENT, AgentConsultEnded, AGENTX_DEFAULT_FOCUS, AX_ANNOUNCEMENT_UPDATE, BARGE_IN_MONITORING_STATE, consultICHeight, CUSTOMER, INTERACTION_EVENTS, mainICHeight, MEDIA_TYPES, MONITORING_TYPE } from "@/app/components/constants";
import { RouteName } from "@/app/routing/routing";
import { handlePresenceStateReset } from "@/app/utils/StateSyncManager/UtilsSyncState";
import { t } from "@/mixins/i18nMixin";
import { createLogger } from "@/sdk";
import { STORE, updatedByFc, updatedByIMI } from "@/store";
import { getTrackingIdFromErrorObject, maskSensitiveData } from "@agentx/agentx-services";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { DateTime } from "luxon";
import { action, computed, observable, toJS } from "mobx";
import { computeAgentCallMonitoringStateDetails, dispatchCustomEvent, getBargedInSupId, getErrorMsgForReasonCode, getFCValue, isDigitalOutbound, isSecondaryEpDnAgent, triggerBargeInNotification } from "../app/utils/Utils";
import { BROWSER, EP_DN, OUTBOUND_REASONS_MAPPING, OUTDIAL_ERROR_REASONS, STATE_CONFERENCE, STATE_CONSULT, STATE_CONSULT_COMPLETED, STATE_CONSULTING, STATE_WRAPUP, SUPERVISOR } from "./shared-actions/constants";
import { findHoldStatus, findHoldTimestamp, findMediaResourceId, getConsultedAgentId, getConsultMPCState, getDestAgentIdForCBT, getIsConferenceInProgress, getIsConsultedAgent, getIsConsultEnabledForMPC, getIsConsultInProgress, getIsSecondaryAgentCBT, getIsWrapupAssist, getPrimaryMediaParticipants, isOwner, isParticipantInMainInteraction } from "./store-contact-helper";
/*
 * (manro):
 *
 * Major part of this STORE module
 * SHOULD BE DONE in EmailComposer <== (yanaha): Not in Email Composer, in new interaction Popover
 */
const logger = createLogger("[store-agent-contact]");
export var MediaType;
(function (MediaType) {
    MediaType["Telephony"] = "telephony";
    MediaType["Chat"] = "chat";
    MediaType["Email"] = "email";
    MediaType["Social"] = "social";
    MediaType["Midcall"] = "midcall telephony";
    MediaType["APPLE_BUSINESS_CHAT"] = "appleMessages";
})(MediaType || (MediaType = {}));
/**
 * masking sensitive dataTypes
 */
export const OUTBOUND = "OUTBOUND";
export const ANI = "ani";
export const DN = "dn";
export const SOURCENUMBER = "sourceNumber";
export const CUSTOMERNUMBER = "customerNumber";
export class ModuleAgentContact {
    constructor() {
        /**
         * Observable block
         */
        this.taskMap = new Map();
        this.EpDnConsultDestinationAgentJoined = false;
        this.lastCompletedTask = "";
        this.selectedTaskId = "";
        this.newOfferPopoverCount = 0;
        this.interactionIds = [];
        this.openCADSection = false;
        this.chatConferenceData = {
            initiateConferenceChat: false,
            interactionId: "",
            destAgentId: "",
            agentId: ""
        };
        this.checkNewReplies = false;
        this.showNewReply = false;
        this.showArrow = false;
        this.copyTaskMap = new Map();
        this.navigationWarning = {
            cadVariables: false,
            chatMessages: false,
            wrapUpReason: false
        };
        this.interactionControlDimension = {
            mainCallContainerHeight: 0,
            consultCallContainerHeight: 0
        };
        this.interactionControlCAD = {
            isMainContainerCADExpanded: false,
            isConsultContainerCADExpanded: false
        };
        this.monitoringOwnerName = "";
        this.isMonitoringNotEndedManually = false;
        this.isWebCallMuted = undefined;
        /**
         * Utils block
         */
        this.popoverEventTypes = [
            "AgentOfferContact",
            "AgentOfferConsult",
            "AgentContact",
            "AgentConsulting",
            "AgentContactAssigned",
            "AgentOfferCampaignReservation",
            "AgentAddCampaignReservation"
        ];
        this.isNonTerminatedTask = (taskObj) => {
            return taskObj && taskObj.interaction && !taskObj.interaction.isTerminated;
        };
        // To check if agent is added to campaign call - hasJoined will come as true in this special case, but interaction panel and task list should not appear
        this.isAgentAddedToCampaign = (data, agentId) => {
            var _a, _b;
            return ((data.type === "AgentAddCampaignReservation" || data.reservedAgentChannelId) &&
                !((_a = data.interaction.participants[agentId]) === null || _a === void 0 ? void 0 : _a.isWrapUp) &&
                ((_b = data.interaction.participants[agentId]) === null || _b === void 0 ? void 0 : _b.hasJoined));
        };
        this.isAgentContactInRingingState = (data) => {
            var _a, _b;
            const agentId = STORE.agent.agentId || "";
            if (data.interaction.state === "new" ||
                data.interaction.state === "connected" ||
                data.interaction.state === "parked") {
                return (this.isAgentAddedToCampaign(data, agentId) ||
                    (!((_a = data.interaction.participants[agentId]) === null || _a === void 0 ? void 0 : _a.isWrapUp) && !((_b = data.interaction.participants[agentId]) === null || _b === void 0 ? void 0 : _b.hasJoined)));
            }
            return false;
        };
        this.isAgentContactInOfferConsultState = (data, agentId) => {
            var _a, _b, _c;
            if (data.interaction.state === "consult" ||
                (STORE.app.featureFlags.isWxccMultiPartyConfEnabled && data.interaction.state === "conference")) {
                return (!((_a = data.interaction.participants[agentId]) === null || _a === void 0 ? void 0 : _a.isWrapUp) &&
                    !((_b = data.interaction.participants[agentId]) === null || _b === void 0 ? void 0 : _b.hasJoined) &&
                    ((_c = data.interaction.participants[agentId]) === null || _c === void 0 ? void 0 : _c.isConsulted));
            }
            return false;
        };
        this.isValidEventForPopover = (task) => {
            if (task.type === "AgentContact") {
                return (this.isAgentContactInRingingState(task) || this.isAgentContactInOfferConsultState(task, STORE.agent.agentId));
            }
            return true;
        };
        // return true for non customer participate.
        this.isNonCustomerParticipant = (participantType) => {
            const isPersistCallOrMPCEnabled = STORE.app.featureFlags.isWxccMultiPartyConfEnabled || STORE.app.featureFlags.isWxccPersistCallEnabled;
            return (participantType === "Agent" ||
                participantType === "DN" ||
                participantType === "EpDn" ||
                (isPersistCallOrMPCEnabled && participantType === "entryPoint"));
        };
        this.getDestinationAgentId = (data, agentId) => {
            let id = "";
            data &&
                Object.keys(data).forEach((participant) => {
                    if (this.isNonCustomerParticipant(data[participant].type) &&
                        data[participant].id !== agentId &&
                        !data[participant].isWrapUp) {
                        id = data[participant].id;
                    }
                });
            return id;
        };
        this.calculateDestAgentId = (interaction, agentId) => {
            var _a, _b, _c;
            if (!STORE.app.featureFlags.isWxccMultiPartyConfEnabled && !STORE.app.featureFlags.isWxccPersistCallEnabled) {
                return this.getDestinationAgentId(interaction.participants, agentId);
            }
            else {
                const consultingAgent = getConsultedAgentId(interaction.media, agentId);
                // in case of non CBT below fun will return empty string so that it will not go inside if condition
                // it will execute the normal flow
                const destAgentIdCBT = getDestAgentIdForCBT(interaction, consultingAgent);
                if (destAgentIdCBT) {
                    return destAgentIdCBT;
                }
                return ((_a = interaction.participants[consultingAgent]) === null || _a === void 0 ? void 0 : _a.type) === EP_DN
                    ? (_b = interaction.participants[consultingAgent]) === null || _b === void 0 ? void 0 : _b.epId
                    : (_c = interaction.participants[consultingAgent]) === null || _c === void 0 ? void 0 : _c.id;
            }
        };
        this.calculateDestAgentIdForFetchingDestType = (interaction, agentId) => {
            if (!STORE.app.featureFlags.isWxccMultiPartyConfEnabled && !STORE.app.featureFlags.isWxccPersistCallEnabled) {
                return this.getDestinationAgentId(interaction.participants, agentId);
            }
            else {
                const consultingAgent = getConsultedAgentId(interaction.media, agentId);
                // in case of non CBT below fun will return empty string
                const destAgentIdCBT = getDestAgentIdForCBT(interaction, consultingAgent);
                return destAgentIdCBT ? destAgentIdCBT : consultingAgent;
            }
        };
        this.getStatus = (data) => {
            var _a;
            const agentId = STORE.agent.agentId;
            const interaction = data.interaction;
            if (isSecondaryEpDnAgent(interaction)) {
                if (interaction.state === STATE_CONFERENCE) {
                    return STATE_CONFERENCE;
                }
                return STATE_CONSULTING; // handle state of child agent case as we cant relay on interaction state.
            }
            if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled) {
                if (data.interaction.state === STATE_WRAPUP &&
                    ((_a = interaction.participants[agentId]) === null || _a === void 0 ? void 0 : _a.consultState) === STATE_CONSULT_COMPLETED) {
                    return STATE_CONSULT_COMPLETED;
                }
                return getConsultMPCState(data, agentId);
            }
            return interaction.state;
        };
        this.isConferencingAfterConsultCompleted = (data) => {
            const status = this.getStatus(data);
            if (status !== "consultCompleted") {
                return false;
            }
            const mediaMainCall = data.interaction.media[data.interactionId];
            const participantsInMainCall = mediaMainCall === null || mediaMainCall === void 0 ? void 0 : mediaMainCall.participants;
            const participants = data.interaction.participants;
            let participantsInCallAfterConsultCompelted = "";
            if (participantsInMainCall) {
                participantsInCallAfterConsultCompelted = participantsInMainCall === null || participantsInMainCall === void 0 ? void 0 : participantsInMainCall.filter((participantId) => participants[participantId] &&
                    participants[participantId].pType !== CUSTOMER &&
                    participants[participantId].pType !== SUPERVISOR &&
                    !participants[participantId].hasLeft);
            }
            return participantsInCallAfterConsultCompelted.length >= 2;
        };
        this.getDestPhoneNumber = (interaction, destAgentId) => {
            var _a, _b;
            if (isSecondaryEpDnAgent(interaction)) {
                return interaction.callProcessingDetails.parent_Agent_DN; // return's primary agent ANI.
            }
            return destAgentId
                ? ((_a = interaction.participants[destAgentId]) === null || _a === void 0 ? void 0 : _a.dn) || ((_b = interaction.participants[destAgentId]) === null || _b === void 0 ? void 0 : _b.id)
                : undefined;
        };
        this.getDestAgentName = (interaction, destAgentId) => {
            var _a;
            if (isSecondaryEpDnAgent(interaction)) {
                return interaction.callProcessingDetails.parent_Agent_Name; // return's primary agent name.
            }
            return destAgentId ? (_a = interaction.participants[destAgentId]) === null || _a === void 0 ? void 0 : _a.name : undefined;
        };
        this.getMediaType = (interaction, agentId) => {
            var _a, _b;
            // as interaction object is same for contact and monitoring
            const participantsRole = (_a = interaction.participants[agentId]) === null || _a === void 0 ? void 0 : _a.pType;
            //check if incoming call is a monitoring call or a consult call which is being monitored
            if (((_b = interaction.callProcessingDetails) === null || _b === void 0 ? void 0 : _b.monitorType) === MONITORING_TYPE.MIDCALL &&
                interaction.owner !== agentId &&
                (participantsRole === null || participantsRole === void 0 ? void 0 : participantsRole.toLowerCase()) === SUPERVISOR) {
                return MEDIA_TYPES.MIDCALL_MONITORING;
            }
            return interaction.mediaType;
        };
        this.getTimeStamp = (interaction, agentId) => {
            var _a, _b;
            let owner = agentId;
            if (this.getMediaType(interaction, agentId) === MEDIA_TYPES.MIDCALL_MONITORING) {
                owner = interaction.owner;
            }
            return ((_a = interaction.participants[owner]) === null || _a === void 0 ? void 0 : _a.joinTimestamp) || ((_b = interaction.participants[owner]) === null || _b === void 0 ? void 0 : _b.lastUpdated);
        };
        this.handleTaskDelete = (taskId, deviceType) => {
            const selectedTask = this.taskMap.get(taskId);
            if (selectedTask && selectedTask.interaction.mediaType === MediaType.Telephony) {
                if (deviceType === BROWSER) {
                    dispatchCustomEvent(window, INTERACTION_EVENTS.AX_INTERACTION_DELETE, taskId);
                }
                handlePresenceStateReset(taskId);
            }
        };
        this.flattenTaskForInteractionControl = (data, agentId, hasCustomerLeft = false, isConvNotFound = false) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
            const mediaType = this.getMediaType(data.interaction, agentId);
            const isWxccMPCEnabled = STORE.app.featureFlags.isWxccMultiPartyConfEnabled;
            //If agent not in consult, then destAgentId will be empty. Else it will be of the other agent who is in consult with this agent
            const destAgentId = this.calculateDestAgentId(toJS(data.interaction), agentId);
            const destAgentIdForFetchingDestType = this.calculateDestAgentIdForFetchingDestType(toJS(data.interaction), agentId);
            const isConferenceInProgress = getIsConferenceInProgress(data);
            return {
                customerName: data.interaction.callProcessingDetails.customerName,
                mediaType,
                mediaChannel: data.interaction.mediaChannel,
                queueId: data.interaction.callProcessingDetails.QueueId,
                ani: data.interaction.callProcessingDetails.ani,
                displayAni: data.interaction.callProcessingDetails.displayAni,
                orgId: data.orgId,
                isFcManaged: data.interaction.isFcManaged,
                owner: data.interaction.owner,
                reasonCode: data.reasonCode,
                interactionId: data.interactionId,
                mediaResourceId: findMediaResourceId(data.interaction.media, "mainCall"),
                isConsulted: getIsConsultedAgent(data),
                state: this.getStatus(data),
                destAgentId,
                destAgentPhoneNumber: this.getDestPhoneNumber(data.interaction, destAgentIdForFetchingDestType),
                destAgentName: this.getDestAgentName(data.interaction, destAgentIdForFetchingDestType) ||
                    data.interaction.callProcessingDetails.consultDestinationAgentName,
                destAgentType: destAgentIdForFetchingDestType
                    ? (_a = data.interaction.participants[destAgentIdForFetchingDestType]) === null || _a === void 0 ? void 0 : _a.type
                    : undefined,
                consultHold: findHoldStatus(data, "consult"),
                consultMediaResourceId: findMediaResourceId(data.interaction.media, "consult"),
                isTerminated: data.interaction.isTerminated,
                agentId,
                ownerName: data.interaction.owner ? (_b = data.interaction.participants[data.interaction.owner]) === null || _b === void 0 ? void 0 : _b.name : undefined,
                ownerPhoneNumber: data.interaction.owner ? (_c = data.interaction.participants[data.interaction.owner]) === null || _c === void 0 ? void 0 : _c.dn : undefined,
                isWrapUp: (_d = data.interaction.participants[agentId]) === null || _d === void 0 ? void 0 : _d.isWrapUp,
                hasJoined: (_e = data.interaction.participants[agentId]) === null || _e === void 0 ? void 0 : _e.hasJoined,
                isOffered: (_g = (_f = data.interaction.participants[agentId]) === null || _f === void 0 ? void 0 : _f.isOffered) !== null && _g !== void 0 ? _g : false,
                dnis: data.interaction.callProcessingDetails.dnis,
                phoneNumber: data.interaction.callProcessingDetails.displayAni,
                virtualTeamName: data.interaction.callProcessingDetails.virtualTeamName,
                ivrPath: data.interaction.callProcessingDetails.IvrPath,
                pathId: data.interaction.callProcessingDetails.pathId,
                ronaTimeout: data.interaction.callProcessingDetails.ronaTimeout,
                category: data.interaction.callProcessingDetails.category,
                additionalDetails: data.interaction.callProcessingDetails.reason,
                sourceNumber: data.interaction.callProcessingDetails.sourceNumber,
                sourcePage: data.interaction.callProcessingDetails.sourcePage,
                appUser: data.interaction.callProcessingDetails.appUser,
                customerNumber: data.interaction.callProcessingDetails.customerNumber,
                isConferencing: ((_h = data.interaction.participants[agentId]) === null || _h === void 0 ? void 0 : _h.hasJoined) &&
                    !((_j = data.interaction.participants[agentId]) === null || _j === void 0 ? void 0 : _j.hasLeft) &&
                    (this.getStatus(data) === STATE_CONFERENCE || this.isConferencingAfterConsultCompleted(data)),
                isRecordingPaused: data.interaction.callProcessingDetails.isPaused === "true",
                isRecorded: data.interaction.callProcessingDetails.recordInProgress === "true",
                isPauseResumeEnabled: data.interaction.callProcessingDetails.pauseResumeEnabled === "true",
                recordingPauseDuration: data.interaction.callProcessingDetails.pauseDuration,
                contactDirection: data.interaction.contactDirection.type,
                outboundType: data.interaction.outboundType,
                isHold: findHoldStatus(data, "mainCall"),
                ctqInProgress: ((_k = data.interaction.callProcessingDetails) === null || _k === void 0 ? void 0 : _k.ctqInProgress) === "true",
                outdialTransferToQueueEnabled: ((_l = data.interaction.callProcessingDetails) === null || _l === void 0 ? void 0 : _l.outdialTransferToQueueEnabled) === "true",
                timeStamp: this.getTimeStamp(data.interaction, agentId),
                consultTimeStamp: ((_m = data.interaction.participants[agentId]) === null || _m === void 0 ? void 0 : _m.consultTimestamp) || ((_o = data.interaction.participants[agentId]) === null || _o === void 0 ? void 0 : _o.lastUpdated),
                wrapUpAssistTimestamp: (_p = data.interaction.participants[agentId]) === null || _p === void 0 ? void 0 : _p.wrapUpTimestamp,
                wrapUpTimestamp: ((_q = data.interaction.participants[agentId]) === null || _q === void 0 ? void 0 : _q.isWrapUp)
                    ? (_r = data.interaction.participants[agentId]) === null || _r === void 0 ? void 0 : _r.lastUpdated
                    : (_s = data.interaction.participants[agentId]) === null || _s === void 0 ? void 0 : _s.wrapUpTimestamp,
                holdTimestamp: findHoldTimestamp(data.interaction, "mainCall"),
                consultHoldTimestamp: findHoldTimestamp(data.interaction, "consult"),
                callAssociatedData: toJS(data.interaction.callAssociatedData),
                hasCustomerLeft,
                isConvNotFound,
                updatedBy: data.updatedBy,
                isOwner: isOwner(data.interaction, agentId),
                parentInteractionId: data.interaction.callProcessingDetails.parentInteractionId,
                EpDnConsultDestinationAgentJoined: ((_u = (_t = data.interaction) === null || _t === void 0 ? void 0 : _t.callProcessingDetails) === null || _u === void 0 ? void 0 : _u.consultDestinationAgentJoined) === "true" ? true : false,
                isSecondaryEpDnAgent: isSecondaryEpDnAgent(data.interaction) ? true : false,
                monitoringStartTime: ((_v = data.interaction.participants[STORE.agent.agentId]) === null || _v === void 0 ? void 0 : _v.joinTimestamp) || DateTime.now().toMillis(),
                type: data.type,
                isMonitoringOnHold: ((_w = data.interaction.participants[STORE.agent.agentId]) === null || _w === void 0 ? void 0 : _w.isOnHold) || false,
                monitoringHoldTimer: ((_x = data.interaction.participants[STORE.agent.agentId]) === null || _x === void 0 ? void 0 : _x.holdTimestamp) || DateTime.now().toMillis(),
                isWebCallMute: this.isWebCallMuted,
                isBargedIn: data.interaction.owner !== agentId &&
                    ((_z = (_y = data.interaction.participants[agentId]) === null || _y === void 0 ? void 0 : _y.pType) === null || _z === void 0 ? void 0 : _z.toLowerCase()) === SUPERVISOR &&
                    ((_1 = (_0 = data.interaction.participants[agentId]) === null || _0 === void 0 ? void 0 : _0.monitoringDetails) === null || _1 === void 0 ? void 0 : _1.type) === BARGE_IN_MONITORING_STATE,
                bargedInTimeStamp: ((_3 = (_2 = data.interaction.participants[agentId]) === null || _2 === void 0 ? void 0 : _2.monitoringDetails) === null || _3 === void 0 ? void 0 : _3.startTimestamp) || DateTime.now().toMillis(),
                fcDesktopViewCad: getFCValue(data),
                primaryMediaParticipants: getPrimaryMediaParticipants(data),
                isConsultEnabledForMPC: getIsConsultEnabledForMPC(data, isWxccMPCEnabled, agentId),
                isConsultInProgress: getIsConsultInProgress(data),
                isConferenceInProgress,
                isWrapUpAssist: getIsWrapupAssist(data.interaction),
                isSecondaryAgentCBT: getIsSecondaryAgentCBT(data.interaction, destAgentIdForFetchingDestType) // determines if the consulted agent or the latest conferenced agent is CBT
            };
        };
        this.flattenTaskForTaskList = (data, agentId) => {
            var _a, _b, _c, _d, _e, _f, _g;
            return {
                customerName: data.interaction.callProcessingDetails.customerName,
                mediaType: this.getMediaType(data.interaction, agentId),
                mediaChannel: data.interaction.mediaChannel,
                ani: data.interaction.callProcessingDetails.ani,
                displayAni: data.interaction.callProcessingDetails.displayAni,
                interactionId: data.interactionId,
                isConsulted: getIsConsultedAgent(data),
                state: this.getStatus(data),
                outboundType: data.interaction.outboundType,
                consultHold: findHoldStatus(data, "consult"),
                dnis: data.interaction.callProcessingDetails.dnis,
                virtualTeamName: data.interaction.callProcessingDetails.virtualTeamName,
                appUser: data.interaction.callProcessingDetails.appUser,
                isConferencing: ((_a = data.interaction.participants[agentId]) === null || _a === void 0 ? void 0 : _a.hasJoined) && data.interaction.state === STATE_CONFERENCE,
                contactDirection: data.interaction.contactDirection.type,
                isHold: findHoldStatus(data, "mainCall"),
                timeStamp: ((_b = data.interaction.participants[agentId]) === null || _b === void 0 ? void 0 : _b.joinTimestamp) || ((_c = data.interaction.participants[agentId]) === null || _c === void 0 ? void 0 : _c.lastUpdated),
                consultTimeStamp: ((_d = data.interaction.participants[agentId]) === null || _d === void 0 ? void 0 : _d.consultTimestamp) || ((_e = data.interaction.participants[agentId]) === null || _e === void 0 ? void 0 : _e.lastUpdated),
                ownerName: data.interaction.owner ? (_f = data.interaction.participants[data.interaction.owner]) === null || _f === void 0 ? void 0 : _f.name : undefined,
                monitoringStartTime: (_g = data.interaction.participants[STORE.agent.agentId]) === null || _g === void 0 ? void 0 : _g.joinTimestamp,
                type: data.type
            };
        };
        this.isPrimary = (data, agentId) => {
            return data.interaction.owner ? data.interaction.owner === agentId : data.agentId === agentId;
        };
        // /* return interactionId.
        //    return parentInteractionId for secondary agent in only EP-DN consult case.
        // */
        this.getInteractionId = () => {
            var _a, _b, _c, _d;
            if (STORE.app.featureFlags.isDesktopConsultToEntryPointDnEnabled &&
                !((_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.isOwner) &&
                ((_b = STORE.agentContact.taskSelected) === null || _b === void 0 ? void 0 : _b.parentInteractionId)) {
                return (_c = STORE.agentContact.taskSelected) === null || _c === void 0 ? void 0 : _c.parentInteractionId;
            }
            return (_d = STORE.agentContact.taskSelected) === null || _d === void 0 ? void 0 : _d.interactionId;
        };
        this.getTaskIdFromConvId = (convId) => {
            for (const task of this.taskMap.values()) {
                if ((task.interaction.mediaType === MediaType.Chat || task.interaction.mediaType === MediaType.Social) &&
                    ((task.consultMediaResourceId && task.consultMediaResourceId.includes(convId)) ||
                        (task.mediaResourceId && task.mediaResourceId.includes(convId)))) {
                    logger.info(`event=getTaskIdFromConvId | Getting taskId=${task.interactionId} from convId=${convId} `);
                    return this.maskTask(task);
                }
            }
            return undefined;
        };
        this.getMediaMgrFromTask = () => {
            var _a;
            const selectedTask = this.taskMap.get(this.selectedTaskId);
            if (selectedTask) {
                const chatId = selectedTask.mediaResourceId ||
                    ((_a = selectedTask.consultMediaResourceId) === null || _a === void 0 ? void 0 : _a.substring(selectedTask.consultMediaResourceId.indexOf("_") + 1));
                return selectedTask.interaction.media[chatId] ? selectedTask.interaction.media[chatId].mediaMgr : null;
            }
            else {
                return null;
            }
        };
        this.getAcceptedTasks = () => {
            const newTask = [];
            this.taskMap.forEach((task) => {
                if (!(this.isAgentContactInRingingState(task) || this.isAgentContactInOfferConsultState(task, STORE.agent.agentId))) {
                    newTask.push(task);
                }
            });
            return newTask;
        };
        this.handleDefaultFocus = () => {
            if (this.taskMap.size === 0) {
                document.dispatchEvent(new CustomEvent(AGENTX_DEFAULT_FOCUS));
            }
        };
        //CX-3596 Method required to check if the navigation is happening by navbar in case of Telephony , in this case dont show warn on navigation
        this.checkTelephonyTask = () => {
            for (const task of this.taskMap.values()) {
                if (this.selectedTaskId === task.interactionId && task.interaction.mediaType === MediaType.Telephony) {
                    return false;
                }
            }
            return true;
        };
        this.isCADExpanded = () => {
            if (STORE.agentContact.taskSelected &&
                this.isTelephoneOrMidcall &&
                STORE.app.isVoiceIntPaneUpdatedByUser !== null) {
                // If user expanded/collapsed interaction control, fetch that state on navigating to Voice interaction task
                return STORE.app.isVoiceIntPaneUpdatedByUser;
            }
            else if (STORE.agentContact.taskSelected &&
                this.isTelephoneOrMidcall &&
                STORE.app.isVoiceIntPaneUpdatedByUser === null) {
                // If user did not change interaction control state(expand/collapse), then fetch from json configuration
                return !STORE.app.collapseVoiceIntPane;
            }
            else {
                return false;
            }
        };
        this.updateConsultingAgentTask = (data) => {
            const custEvent = {
                detail: {
                    message: data.interaction.mediaType === MediaType.Telephony
                        ? getErrorMsgForReasonCode(data.reasonCode) || t("app:consult.consultFailed")
                        : getErrorMsgForReasonCode(data.reasonCode) || t("app:consult.conferenceFailed"),
                    trackingId: data.trackingId
                }
            };
            STORE.agentContact.updateForConsultFailed(data);
            if ((STORE.app.featureFlags.isWxccMultiPartyConfEnabled || STORE.app.featureFlags.isWxccPersistCallEnabled) &&
                data.interaction.state === "consult") {
                STORE.session.errorModal.setErrorDetails(custEvent);
            }
            //  Handling to avoid show error in Transfer modal inline as well as Error Modal in case of AgentConsultFailed
            if (data.interaction.state !== "consult" && data.interaction.state !== "none") {
                STORE.session.errorModal.setErrorDetails(custEvent);
            }
        };
        this.checkParticipantNotInInteraction = (agentId, data) => {
            return (!(agentId in data.interaction.participants) ||
                (agentId in data.interaction.participants && data.interaction.participants[agentId].hasLeft));
        };
        this.removeTaskOnConsultEnd = (interaction) => {
            this.deleteAndSelectNextTask(interaction.interactionId);
            if (interaction.mediaType === MediaType.Chat || interaction.mediaType === MediaType.Social) {
                STORE.session.chat.deleteUnreadMessages(interaction.interactionId);
            }
            this.updateLastCompletedTask("");
        };
        this.updateInteractionAndMediaObject = (data) => {
            const task = this.taskMap.get(data.interactionId);
            if (task && task.interaction.participants && task.interaction.media) {
                task.interaction.participants = data.interaction.participants;
                task.interaction.media = data.interaction.media;
                task.interaction.state = data.interaction.state;
                this.taskMap.set(data.interactionId, task);
                this.copyTaskMap.set(task.interactionId, task);
            }
        };
        this.updateTaskForParticipant = (data, agentId) => {
            //if agent is primary, update task
            //else remove task on consult end
            if (this.isPrimary(data, agentId)) {
                this.resetInteractionControlDimensions();
                this.updateTask(data);
            }
            else {
                this.removeTaskOnConsultEnd(data.interaction);
            }
        };
        this.updateTaskForPersistCallOrMPC = (data, agentId) => {
            //if agent is primary, update interaction and media object,
            //else remove task on consult end
            if (isParticipantInMainInteraction(data.interaction, agentId) || this.isPrimary(data, agentId)) {
                this.resetInteractionControlDimensions();
                this.updateInteractionAndMediaObject(data);
            }
            else {
                this.removeTaskOnConsultEnd(data.interaction);
            }
        };
        this.getTotalAgentParticipants = (currentParticipants) => {
            return Object.values(currentParticipants).filter((currentParticipant) => currentParticipant.pType === AGENT && currentParticipant.hasJoined).length;
        };
        this.getParticipantLeft = (data) => {
            var _a, _b;
            const prevTask = this.taskMap.get(data.interactionId);
            let participantLeft;
            if (prevTask) {
                const prevParticipants = (_a = prevTask.interaction) === null || _a === void 0 ? void 0 : _a.participants;
                participantLeft = data.participantId ? (_b = prevParticipants[data.participantId]) === null || _b === void 0 ? void 0 : _b.name : undefined;
            }
            return {
                participantName: participantLeft,
                totalParticipants: this.getTotalAgentParticipants(data.interaction.participants)
            };
        };
        this.getParticipantAdded = (data) => {
            var _a, _b;
            const currentParticipants = (_a = data.interaction) === null || _a === void 0 ? void 0 : _a.participants;
            return {
                participantName: data.participantId ? (_b = currentParticipants[data.participantId]) === null || _b === void 0 ? void 0 : _b.name : undefined,
                totalParticipants: this.getTotalAgentParticipants(data.interaction.participants)
            };
        };
        this.updateTaskOnParticipantLeft = (data, agentId) => {
            const { participantName, totalParticipants } = this.getParticipantLeft(data);
            if (participantName && totalParticipants) {
                window.dispatchEvent(new CustomEvent(AX_ANNOUNCEMENT_UPDATE, {
                    detail: {
                        thisEvent: {
                            message: `${participantName} ${t("app:common.leftConference", "", { count: totalParticipants })}`
                        }
                    }
                }));
            }
            if (STORE.app.featureFlags.isWxccPersistCallEnabled) {
                //if the persist call ff is ON, check if agent has left or not [check if his id is in participants and hasLeft is true, or not in participants]
                if (this.checkParticipantNotInInteraction(agentId, data)) {
                    //if agent has left the interaction, go to check for primary agent and update, else remove for secondary agent
                    this.updateTaskForPersistCallOrMPC(data, agentId);
                }
                else {
                    //else update interaction and media directly
                    this.updateInteractionAndMediaObject(data);
                }
                //on ff removal, the above if-else needs to be kept as is
            }
            else if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled) {
                //if only the multi party ff is ON, go to check for primary agent and update, else remove for secondary agent
                this.updateTaskForPersistCallOrMPC(data, agentId);
            }
            else {
                //else go to check for primary agent and update full task, else remove for secondary agent
                this.updateTaskForParticipant(data, agentId);
            }
        };
        this.handleAgentContactAssignFailure = (data) => {
            if (isDigitalOutbound(data.interaction)) {
                logger.info("[Digital]: Agent contact assign failure for interactionId ", data.interactionId);
                STORE.session.errorModal.setErrorDetails({
                    detail: {
                        modalType: "dialog",
                        message: t("app:outbound.errorDigitalOutboundAcceptFailed"),
                        trackingId: data.trackingId
                    }
                });
            }
        };
    }
    maskTask(task) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!STORE.agent.getIsMaskSensitiveDataEnabled || !task.interaction || typeof maskSensitiveData !== "function") {
            return Object.assign(Object.assign({}, task), { interaction: Object.assign(Object.assign({}, task === null || task === void 0 ? void 0 : task.interaction), { callProcessingDetails: Object.assign(Object.assign({}, (_a = task === null || task === void 0 ? void 0 : task.interaction) === null || _a === void 0 ? void 0 : _a.callProcessingDetails), { displayAni: (_c = (_b = task === null || task === void 0 ? void 0 : task.interaction) === null || _b === void 0 ? void 0 : _b.callProcessingDetails) === null || _c === void 0 ? void 0 : _c.ani }) }) });
        }
        const maskedCallProcessingDetails = Object.assign(Object.assign({}, task.interaction.callProcessingDetails), { displayAni: maskSensitiveData((_d = task.interaction.callProcessingDetails) === null || _d === void 0 ? void 0 : _d.ani), customerName: maskSensitiveData((_e = task.interaction.callProcessingDetails) === null || _e === void 0 ? void 0 : _e.customerName), appUser: maskSensitiveData((_f = task.interaction.callProcessingDetails) === null || _f === void 0 ? void 0 : _f.appUser), customerNumber: maskSensitiveData((_g = task.interaction.callProcessingDetails) === null || _g === void 0 ? void 0 : _g.customerNumber), sourceNumber: task.interaction.contactDirection.type === OUTBOUND
                ? (_h = task.interaction.callProcessingDetails) === null || _h === void 0 ? void 0 : _h.sourceNumber
                : maskSensitiveData((_j = task.interaction.callProcessingDetails) === null || _j === void 0 ? void 0 : _j.sourceNumber), dnis: maskSensitiveData((_k = task.interaction.callProcessingDetails) === null || _k === void 0 ? void 0 : _k.dnis) });
        let maskCallAssociatedDataTask;
        if (task.interaction.contactDirection.type === OUTBOUND) {
            maskCallAssociatedDataTask = this.maskCallAssociatedData(task, DN);
        }
        else {
            maskCallAssociatedDataTask = this.maskCallAssociatedData(task, ANI);
            maskCallAssociatedDataTask = this.maskCallAssociatedData(maskCallAssociatedDataTask, SOURCENUMBER);
            maskCallAssociatedDataTask = this.maskCallAssociatedData(maskCallAssociatedDataTask, CUSTOMERNUMBER);
        }
        return Object.assign(Object.assign({}, task), { interaction: Object.assign(Object.assign({}, task.interaction), { callProcessingDetails: maskedCallProcessingDetails, callAssociatedData: maskCallAssociatedDataTask.interaction.callAssociatedData }) });
    }
    maskCallAssociatedData(task, property) {
        var _a, _b, _c;
        let maskedCallAssociatedData;
        const callAssociatedData = (_a = task.interaction) === null || _a === void 0 ? void 0 : _a.callAssociatedData;
        if ((callAssociatedData === null || callAssociatedData === void 0 ? void 0 : callAssociatedData[property]) &&
            ((_b = callAssociatedData === null || callAssociatedData === void 0 ? void 0 : callAssociatedData[property]) === null || _b === void 0 ? void 0 : _b.value) &&
            typeof ((_c = callAssociatedData === null || callAssociatedData === void 0 ? void 0 : callAssociatedData[property]) === null || _c === void 0 ? void 0 : _c.value) === "string") {
            const maskedValue = maskSensitiveData(callAssociatedData[property].value);
            maskedCallAssociatedData = Object.assign(Object.assign({}, callAssociatedData), { [property]: Object.assign(Object.assign({}, callAssociatedData[property]), { value: maskedValue }) });
        }
        else {
            maskedCallAssociatedData = callAssociatedData;
        }
        return Object.assign(Object.assign({}, task), { interaction: Object.assign(Object.assign({}, task.interaction), { callAssociatedData: maskedCallAssociatedData }) });
    }
    get getEngagedStatus() {
        return this.getAcceptedTasks().length > 0;
    }
    get getIsAgentOnActiveCall() {
        const taskArr = [...this.taskMap.values()];
        return taskArr.some((task) => task.interaction && this.getMediaType(task.interaction, STORE.agent.agentId) === MediaType.Telephony);
    }
    checkBeforeNavigatingTask(taskId) {
        if (!STORE.common.isInteractionModelOpen) {
            if (STORE.agentContact.checkBeforeNavigation) {
                STORE.routing.showNavigationErrorModal(() => {
                    STORE.agentContact.selectedTaskChange(taskId);
                    STORE.routing.navigateTask(taskId);
                });
            }
            else {
                STORE.agentContact.selectedTaskChange(taskId);
                STORE.routing.navigateTask(taskId);
            }
        }
    }
    get isTelephoneOrMidcall() {
        var _a, _b;
        return (((_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.mediaType) === MediaType.Telephony ||
            ((_b = STORE.agentContact.taskSelected) === null || _b === void 0 ? void 0 : _b.mediaType) === MediaType.Midcall);
    }
    checkBeforeSelectingTask(taskId) {
        if (!STORE.app.stopNavigateOnAcceptTask || STORE.routing.currentRouteName === RouteName.TASK) {
            this.checkBeforeNavigatingTask(taskId);
        }
    }
    checkReplies() {
        this.checkNewReplies = !this.checkNewReplies;
    }
    isTaskRinging(taskId) {
        const data = this.taskMap.get(taskId);
        if (data) {
            return (this.isAgentContactInRingingState(data) || this.isAgentContactInOfferConsultState(data, STORE.agent.agentId));
        }
        return false;
    }
    get showNewReplyButton() {
        return this.showNewReply;
    }
    get showArrowUp() {
        return this.showArrow;
    }
    // Check to determine is there are any active tasks.
    isTaskPresent() {
        // returns true which indicates there are active task with agent.
        return this.taskMap.size > 0;
    }
    // Check to determine is there more than one task.
    isAgentHandlingMoreThanOneTask() {
        return this.taskMap.size > 1;
    }
    /**
     * Computed block
     */
    get contact() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return {
            mediaViewInitState: STORE.session.chat.mediaInitState ? STORE.session.chat.mediaInitState : { state: "LOADING" },
            mediaResourceId: (_b = (_a = this.taskSelected) === null || _a === void 0 ? void 0 : _a.mediaResourceId) !== null && _b !== void 0 ? _b : "",
            mediaType: (_d = (_c = this.taskSelected) === null || _c === void 0 ? void 0 : _c.mediaType) !== null && _d !== void 0 ? _d : "",
            customerName: (_f = (_e = this.taskSelected) === null || _e === void 0 ? void 0 : _e.customerName) !== null && _f !== void 0 ? _f : "",
            queueId: (_h = (_g = this.taskSelected) === null || _g === void 0 ? void 0 : _g.queueId) !== null && _h !== void 0 ? _h : "",
            enterpriseId: STORE.agent.enterpriseId,
            chatMessages: STORE.session.chat.chatMessage
        };
    }
    get shouldDisableOutdialForExclusive() {
        if (STORE.app.featureFlags.isWxccAllowOutdialCallAlwaysEnabled) {
            return false;
        }
        return STORE.agent.profileType === "EXCLUSIVE" && this.taskMap.size !== 0;
    }
    get taskSelected() {
        if (STORE.routing.currentRouteName === RouteName.TASKPANEL || STORE.routing.currentRouteName === RouteName.TASK) {
            const newTask = this.taskMap.get(this.selectedTaskId);
            if (newTask) {
                const hasCustomerLeft = STORE.session.chat.hasCustomerLeft.get(newTask.interactionId);
                const isConvNotFound = STORE.session.chat.isConvNotFound.get(newTask.interactionId);
                const t = toJS(this.flattenTaskForInteractionControl(this.maskTask(newTask), STORE.agent.agentId, hasCustomerLeft, isConvNotFound));
                return t !== null && t !== void 0 ? t : null;
            }
        }
        else {
            for (const task of this.taskMap.values()) {
                if (task.interaction.mediaType === MediaType.Telephony &&
                    !(this.isAgentContactInRingingState(task) || this.isAgentContactInOfferConsultState(task, STORE.agent.agentId))) {
                    const t = toJS(this.flattenTaskForInteractionControl(this.maskTask(task), STORE.agent.agentId));
                    return t !== null && t !== void 0 ? t : null;
                }
            }
        }
        return null;
    }
    get isNotTelephoneOrMidcall() {
        var _a, _b;
        return (((_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.mediaType) !== MediaType.Telephony &&
            ((_b = STORE.agentContact.taskSelected) === null || _b === void 0 ? void 0 : _b.mediaType) !== MediaType.Midcall);
    }
    get callPersistence() {
        if (STORE.routing.currentRouteName === RouteName.TASKPANEL || STORE.routing.currentRouteName === RouteName.TASK) {
            return this.isCADExpanded();
        }
        else {
            return false;
        }
    }
    get isMediaTypeTelePhony() {
        var _a;
        return ((_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.mediaType) === MediaType.Telephony ? true : false;
    }
    get taskMapForPopover() {
        const popoverTaskMap = {};
        this.taskMap.forEach((task, key) => {
            if (task.type === "AgentContactReserved" ||
                task.type === "MonitoringOffered" ||
                (this.popoverEventTypes.includes(task.type) &&
                    this.isNonTerminatedTask(task) &&
                    this.isValidEventForPopover(task))) {
                popoverTaskMap[key] = this.maskTask(task);
            }
        });
        return popoverTaskMap;
    }
    get taskArray() {
        return this.getAcceptedTasks().map((task) => this.flattenTaskForTaskList(this.maskTask(task), STORE.agent.agentId));
    }
    get isActiveCall() {
        for (const task of this.taskMap.values()) {
            if (task.interaction.mediaType === MediaType.Telephony) {
                return true;
            }
        }
        return false;
    }
    get newPopoverCount() {
        return this.newOfferPopoverCount;
    }
    get isDefinedIvrTranscript() {
        const selectedTask = this.taskMap.get(this.selectedTaskId);
        const convIvrTranscript = selectedTask === null || selectedTask === void 0 ? void 0 : selectedTask.interaction.callProcessingDetails.convIvrTranscript;
        return JSON.parse(convIvrTranscript || "false");
    }
    get getCallGuideProps() {
        var _a, _b, _c, _d, _e;
        if (((_a = this.taskSelected) === null || _a === void 0 ? void 0 : _a.outboundType) && ((_b = this.taskSelected) === null || _b === void 0 ? void 0 : _b.callAssociatedData)) {
            return {
                LCMKey: toJS((_c = this.taskSelected) === null || _c === void 0 ? void 0 : _c.callAssociatedData).LCMKey.value,
                outboundType: (_d = this.taskSelected) === null || _d === void 0 ? void 0 : _d.outboundType,
                owner: (_e = this.taskSelected) === null || _e === void 0 ? void 0 : _e.owner
            };
        }
        return undefined;
    }
    get getCADSectionOpenStatus() {
        return this.openCADSection;
    }
    get getMainCadSectionStatus() {
        if (!this.openCADSection) {
            return this.interactionControlCAD.isMainContainerCADExpanded;
        }
        return false;
    }
    get getcheckReplies() {
        return this.checkNewReplies;
    }
    get getConsultCadSectionStatus() {
        if (!this.openCADSection) {
            return this.interactionControlCAD.isConsultContainerCADExpanded;
        }
        return false;
    }
    setInteractionIds(interactionIds) {
        this.interactionIds = interactionIds;
    }
    removeAutoCompleteTasks() {
        this.taskMap.forEach((data) => {
            if (!this.interactionIds.includes(data.interactionId)) {
                this.copyTaskMap.delete(data.interactionId);
            }
        });
        this.taskMap = this.copyTaskMap;
    }
    /**
     * Actions block
     */
    selectedTaskChange(taskId) {
        this.selectedTaskId = taskId;
    }
    //TODO: to be removed as its a workaround for props not rendering in interaction control in wrap up on reload
    rerenderTaskMap() {
        this.taskMap = this.copyTaskMap;
    }
    //TODO: Once task is completed (wrapped up), complted task is should be updated in the store STORE.agentContact.lastcompletedtask.taskId? -->
    updateLastCompletedTask(taskId) {
        this.lastCompletedTask = taskId;
    }
    updateCadSectionDimension(key, value) {
        this.interactionControlDimension[key] = value;
    }
    setInteractionControlDimensions() {
        if (this.interactionControlDimension.consultCallContainerHeight > mainICHeight) {
            this.interactionControlDimension.mainCallContainerHeight = mainICHeight;
            this.interactionControlDimension.consultCallContainerHeight = consultICHeight;
        }
        else {
            this.interactionControlDimension.mainCallContainerHeight = mainICHeight;
        }
    }
    toggleInteractionControlCADSection(isOpen) {
        if (isOpen) {
            this.openCADSection = true;
            this.interactionControlCAD.isMainContainerCADExpanded =
                this.interactionControlDimension.mainCallContainerHeight > mainICHeight;
            this.interactionControlCAD.isConsultContainerCADExpanded =
                this.interactionControlDimension.consultCallContainerHeight > mainICHeight;
            this.setInteractionControlDimensions();
        }
        else {
            this.openCADSection = false;
        }
    }
    updateRecordingStatus(taskId, isPaused) {
        if (this.taskMap.has(taskId)) {
            const task = this.taskMap.get(taskId);
            if (task) {
                task.interaction.callProcessingDetails.isPaused = isPaused;
                this.taskMap.set(taskId, task);
            }
        }
    }
    updateRecordingProps(newTask) {
        const task = this.taskMap.get(newTask.interactionId);
        if (task) {
            task.interaction.callProcessingDetails.recordInProgress =
                newTask.interaction.callProcessingDetails.recordInProgress;
            task.interaction.callProcessingDetails.pauseDuration = newTask.interaction.callProcessingDetails.pauseDuration;
            task.interaction.callProcessingDetails.pauseResumeEnabled =
                newTask.interaction.callProcessingDetails.pauseResumeEnabled;
            task.interaction.callProcessingDetails.isPaused = newTask.interaction.callProcessingDetails.isPaused;
            this.taskMap.set(newTask.interactionId, task);
        }
    }
    updateShowNewReply(flag) {
        this.showNewReply = flag;
    }
    updateShowArrowUp(flag) {
        this.showArrow = flag;
    }
    updateHeldUnheldProps(newTask) {
        const task = this.taskMap.get(newTask.interactionId);
        if (task) {
            task.interaction = newTask.interaction;
            this.taskMap.set(newTask.interactionId, task);
        }
    }
    updateHeldUnheldPropsMonitoring(newTask) {
        const task = this.taskMap.get(newTask.interactionId);
        if (task) {
            // setting isOnHold and holdTimestamp properties of supervisor participants for hold and unhold events
            if (newTask.type === "MonitoringHeld") {
                task.interaction.participants[STORE.agent.agentId].isOnHold = true;
                task.interaction.participants[STORE.agent.agentId].holdTimestamp = DateTime.now().toMillis();
            }
            else if (newTask.type === "MonitoringUnHeld") {
                task.interaction.participants[STORE.agent.agentId].isOnHold = false;
                task.interaction.participants[STORE.agent.agentId].holdTimestamp = 0;
            }
            task.type = newTask.type;
            this.taskMap.set(newTask.interactionId, task);
        }
    }
    updateBargeInProps(bargeInDetails) {
        const task = this.taskMap.get(bargeInDetails.interactionId);
        if (task) {
            task.type = bargeInDetails.type;
            task.interaction = bargeInDetails.interaction;
            this.taskMap.set(bargeInDetails.interactionId, task);
        }
    }
    get getAgentCallMonitoringStateDetails() {
        const selectedTask = this.taskMap.get(this.selectedTaskId);
        if (this.agentCallMonitoringState &&
            selectedTask &&
            this.agentCallMonitoringState.interactionId === selectedTask.interactionId) {
            return this.agentCallMonitoringState;
        }
        return undefined;
    }
    setAgentCallMonitoringStateDetails(agentCallMonitoringStateDetails) {
        this.agentCallMonitoringState = agentCallMonitoringStateDetails;
    }
    updateAgentCallMonitoringStateDetails(task) {
        this.agentCallMonitoringState = computeAgentCallMonitoringStateDetails(task.interaction);
    }
    updateInteractionAndMediaObjectForMonitorStateChangeEvent(newTask) {
        const task = this.taskMap.get(newTask.interactionId);
        if (task && task.interaction.participants && task.interaction.media) {
            task.interaction.participants = newTask.interaction.participants;
            task.interaction.media = newTask.interaction.media;
            this.taskMap.set(newTask.interactionId, task);
        }
    }
    removeSupervisorFromInteractionAndMediaObject(newTask) {
        const task = this.taskMap.get(newTask.taskId);
        if (!task) {
            return;
        }
        const { interaction } = task;
        const supervisorId = newTask.supervisorId;
        // Remove supervisor from interaction participants
        if (interaction.participants[supervisorId]) {
            delete interaction.participants[supervisorId];
        }
        // Remove supervisor from media participants
        const mainLegInteractionId = interaction.mainInteractionId;
        if (mainLegInteractionId && interaction.media[mainLegInteractionId]) {
            interaction.media[mainLegInteractionId].participants = interaction.media[mainLegInteractionId].participants.filter((participantId) => participantId !== supervisorId);
        }
        this.taskMap.set(task.interactionId, task);
    }
    handleConfParticipantJoined(newTask) {
        const { participantName, totalParticipants } = this.getParticipantAdded(newTask);
        if (participantName && totalParticipants) {
            window.dispatchEvent(new CustomEvent(AX_ANNOUNCEMENT_UPDATE, {
                detail: {
                    thisEvent: {
                        message: `${participantName} ${t("app:common.joinedConference", "", { count: totalParticipants })}`
                    }
                }
            }));
        }
        this.updateInteractionAndMediaObject(newTask);
    }
    updateCadVariable(newTask) {
        var _a, _b;
        const task = this.taskMap.get(newTask.interactionId);
        const agentId = STORE.agent.agentId;
        // Update callProcessingDetails object, when changes in consultDestinationAgentJoined flag on EP-DN consult scenario
        if (task &&
            STORE.app.featureFlags.isDesktopConsultToEntryPointDnEnabled &&
            ((_a = newTask.interaction.callProcessingDetails) === null || _a === void 0 ? void 0 : _a.relationshipType) === "consult" &&
            ((_b = newTask.interaction.callProcessingDetails) === null || _b === void 0 ? void 0 : _b.consultDestinationAgentJoined)) {
            task.interaction.callProcessingDetails.consultDestinationAgentJoined =
                newTask.interaction.callProcessingDetails.consultDestinationAgentJoined;
            task.interaction.callProcessingDetails.consultDestinationAgentName =
                newTask.interaction.callProcessingDetails.consultDestinationAgentName;
            this.taskMap.set(newTask.interactionId, task);
        }
        // update cad only when one of the particpents updates it.
        if (task &&
            (newTask.updatedBy === agentId ||
                newTask.updatedBy === this.getDestinationAgentId(task === null || task === void 0 ? void 0 : task.interaction.participants, agentId) ||
                newTask.updatedBy === updatedByFc ||
                newTask.updatedBy === updatedByIMI)) {
            task.interaction.callAssociatedData = newTask.interaction.callAssociatedData;
            task.updatedBy = newTask.updatedBy;
            this.taskMap.set(newTask.interactionId, task);
            if (newTask.updatedBy !== agentId && newTask.updatedBy !== updatedByFc && newTask.updatedBy !== updatedByIMI) {
                const notificationData = {
                    type: Notifications.ItemMeta.Type.Warn,
                    mode: Notifications.ItemMeta.Mode.AutoDismiss,
                    title: t("app:notifications.CADUpdateTitle"),
                    data: t("app:notifications.CADUpdateData")
                };
                STORE.generalNotifications.fireNotificationEvent(notificationData);
            }
        }
    }
    selectNextTask(nextTaskId) {
        // Updating Store route (id)
        this.selectedTaskChange(nextTaskId);
        if (STORE.routing.currentRouteName === RouteName.TASKPANEL) {
            // Navigate to nextTask if exists
            if (nextTaskId) {
                STORE.routing.navigateTask(nextTaskId);
            }
            else {
                // IF SupervisorAgent is on task page and if call ends stay on same page
                if (STORE.agent.role === SUPERVISOR) {
                    STORE.routing.navigate(RouteName.TASK);
                }
                else {
                    // Navigate to home url if no more tasks for agents.
                    STORE.routing.navigateHome();
                }
            }
        }
    }
    updateTask(task) {
        if (this.interactionIds.indexOf(task.interactionId) === -1) {
            this.interactionIds.push(task.interactionId);
        }
        this.taskMap.set(task.interactionId, task);
        this.copyTaskMap.set(task.interactionId, task);
    }
    deleteAndSelectNextTask(taskId) {
        if (this.taskMap.has(taskId)) {
            this.deleteTask(taskId);
        }
        STORE.session.ivrTransctipt.deleteTranscript(taskId);
        let nextTaskId = "";
        this.taskMap.forEach((data) => {
            if (!this.isAgentContactInRingingState(data)) {
                nextTaskId = data.interactionId;
            }
        });
        if (this.selectedTaskId === taskId) {
            this.selectNextTask(nextTaskId);
        }
        if (this.lastCompletedTask !== taskId) {
            this.updateLastCompletedTask(taskId);
        }
        else {
            this.updateLastCompletedTask("");
        }
        this.handleDefaultFocus();
    }
    deleteTask(taskId, isContactMerged = false) {
        if (!isContactMerged) {
            this.handleTaskDelete(taskId, STORE.agent.deviceType);
        }
        this.taskMap.delete(taskId);
        this.copyTaskMap.delete(taskId);
        if (this.interactionIds.indexOf(taskId) !== -1) {
            const index = this.interactionIds.indexOf(taskId);
            this.interactionIds.splice(index, 1);
        }
    }
    updateForCTQ(taskId) {
        const selectedTask = this.taskMap.get(taskId);
        if (selectedTask) {
            selectedTask.interaction.state = "connected";
            this.taskMap.set(taskId, selectedTask);
        }
    }
    updateForCTQFailure(task) {
        const selectedTask = this.taskMap.get(task.interactionId);
        if ((selectedTask === null || selectedTask === void 0 ? void 0 : selectedTask.interaction.state) === STATE_CONSULTING) {
            const custEvent = {
                detail: {
                    message: getErrorMsgForReasonCode(task.reasonCode) || t("app:consult.cancelCtqFailed"),
                    trackingId: task.trackingId
                }
            };
            STORE.session.errorModal.setErrorDetails(custEvent);
        }
    }
    updateForConsultFailed(task) {
        const selectedTask = this.taskMap.get(task.interactionId);
        if (selectedTask && task.interaction.state !== "none") {
            // #CX-4221 issue fix
            selectedTask.interaction.state = task.interaction.state;
            this.taskMap.set(task.interactionId, selectedTask);
        }
    }
    handleAgentConsultFailed(data, agentId) {
        if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled) {
            //Finding agent who started consult, by previous state.
            const task = this.taskMap.get(data.interactionId);
            const previousStatus = task ? this.getStatus(task) : undefined;
            if (previousStatus && previousStatus === STATE_CONSULT) {
                this.updateConsultingAgentTask(data);
            }
            else {
                //Updating conferenced agent UI when consult failed
                if (data.interaction.media[data.interactionId].participants.includes(agentId) && task) {
                    task.interaction = data.interaction;
                    this.taskMap.set(data.interactionId, task);
                }
                else {
                    //If the agent is who was consulted
                    STORE.agentContact.deleteAndSelectNextTask(data.interactionId);
                }
            }
        }
        else {
            if (!this.isPrimary(data, agentId)) {
                STORE.agentContact.deleteAndSelectNextTask(data.interactionId);
            }
            else {
                this.updateConsultingAgentTask(data);
            }
        }
    }
    selectAcceptedTask(data, agentId) {
        if (!this.isPrimary(data, agentId)) {
            this.checkBeforeSelectingTask(data.interactionId);
        }
    }
    resetInteractionControlDimensions() {
        this.interactionControlDimension.consultCallContainerHeight = 0;
    }
    handleConsultUpdate(data, agentId) {
        if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled && data.type === AgentConsultEnded) {
            // consult-end event for MPC FF enabled flow
            // updating all agent consult/conferenced agent interaction panel
            isParticipantInMainInteraction(data.interaction, agentId)
                ? this.updateTask(data)
                : this.removeTaskOnConsultEnd(data.interaction);
        }
        else {
            // consult-end event for non-MPC FF enabled flow, conference-end flow and participant-left-conf flow
            this.updateTaskOnParticipantLeft(data, agentId);
        }
    }
    handleConfParticipantLeft(newTask, agentId) {
        if (this.taskMap.get(newTask.interactionId)) {
            this.updateTaskOnParticipantLeft(newTask, agentId);
        }
    }
    handleContactOwnerChanged(data, agentId) {
        const task = this.taskMap.get(data.interactionId);
        if (data.toOwner === agentId) {
            if (task) {
                task.interaction = data.interaction;
                this.taskMap.set(data.interactionId, task);
                this.copyTaskMap.set(task.interactionId, task);
            }
            else {
                STORE.agentContact.updateTask(data);
                STORE.agentContact.checkBeforeSelectingTask(data.interactionId);
            }
        }
    }
    handleConsulting(data, agentId) {
        // Handling for Chat Consult Conference failure as the task gets stuck in consulting state on secondary agent side
        if (!this.isPrimary(data, agentId) &&
            (data.interaction.mediaType === MediaType.Chat || data.interaction.mediaType === MediaType.Social)) {
            return;
        }
        STORE.agentContact.updateTask(data);
        STORE.agentContact.selectAcceptedTask(data, agentId);
    }
    updatePopoverCount(popoverCount) {
        this.newOfferPopoverCount = popoverCount;
    }
    selectTaskOnHomeRoute() {
        const acceptedTasks = this.getAcceptedTasks();
        if (acceptedTasks && acceptedTasks.length > 0) {
            const selectedTask = acceptedTasks.find((task) => task.interaction.mediaType === MediaType.Telephony);
            if (selectedTask) {
                STORE.agentContact.selectedTaskChange(selectedTask.interactionId);
                STORE.routing.navigateTask(selectedTask.interactionId);
            }
            else {
                STORE.agentContact.selectedTaskChange(acceptedTasks[0].interactionId);
                STORE.routing.navigateTask(acceptedTasks[0].interactionId);
            }
        }
    }
    handleContactCleanup(data) {
        this.deleteAndSelectNextTask(data.interactionId);
        if (data.mediaType === MediaType.Chat || data.mediaType === MediaType.Social) {
            STORE.session.chat.deleteUnreadMessages(data.interactionId);
        }
        this.updateLastCompletedTask("");
    }
    handleAbruptMonitoringEnd() {
        if (!this.isMonitoringNotEndedManually) {
            STORE.session.errorModal.setErrorDetails({
                detail: {
                    header: t("app:monitoring.monitoringEndedTitle"),
                    modalType: "dialog",
                    message: t("app:monitoring.monitoringEndedAbruptly")
                }
            });
            STORE.browserNotifications.firebrowserNotification(t("app:monitoring.monitoringEndedAbruptly"), t("app:monitoring.monitoringEndedTitle"));
        }
        this.setIsMonitoringNotEndedManually(false);
    }
    handleOutBoundFailed(data) {
        let errorString;
        if (data.interaction.outboundType === "OUTDIAL" && !isDigitalOutbound(data.interaction)) {
            const errorMessage = getErrorMsgForReasonCode(data.reasonCode);
            if (errorMessage) {
                errorString = errorMessage;
            }
            else {
                errorString = (data.reason && t(OUTDIAL_ERROR_REASONS[data.reason])) || t("app:outbound.errorOutdialFailed");
            }
        }
        else if (isDigitalOutbound(data.interaction)) {
            /*
            As of today, when DesktopReasonCode feature is Enabled, error messages for a few events are picked based on reasonCode
            However, those error messages are voice specific and need proper restructuring.
            As the DesktopReasonCode feature is not fully developed on desktop, a generic message is shown for
            digital outbound failure scenarios except known scenarios.
             */
            errorString =
                (data.reason && t(OUTBOUND_REASONS_MAPPING[data.reason])) || t("app:outbound.errorDigitalOutboundFailed");
        }
        else if (data.interaction.outboundType === "CAMPAIGN") {
            errorString = t("app:outbound.errorCampaignFailed");
        }
        else if (data.interaction.outboundType === "CALLBACK") {
            errorString = t("app:outbound.errorCallbackFailed");
        }
        else {
            errorString = t("app:outbound.errorOutdialFailed");
        }
        const custEvent = {
            detail: {
                message: errorString,
                trackingId: data.trackingId
            }
        };
        STORE.session.errorModal.setErrorDetails(custEvent);
    }
    handleChatConferenceFailed(data) {
        const custEvent = {
            detail: {
                message: t("app:consult.conferenceFailed"),
                trackingId: getTrackingIdFromErrorObject(data)
            }
        };
        STORE.session.errorModal.setErrorDetails(custEvent);
    }
    updateCallMonitoringObjAndTriggerNotification(task) {
        var _a, _b, _c;
        const participants = task.interaction.participants;
        if (participants) {
            const bargedSupervisorId = getBargedInSupId(participants);
            if (!bargedSupervisorId) {
                return;
            }
            const agentCallMonitoringState = {
                monitoringType: (_a = participants[bargedSupervisorId].monitoringDetails) === null || _a === void 0 ? void 0 : _a.type,
                supervisorId: bargedSupervisorId,
                supervisorName: participants[bargedSupervisorId].name,
                interactionId: task.interactionId,
                agentId: task.agentId,
                eventTime: (_b = participants[bargedSupervisorId].monitoringDetails) === null || _b === void 0 ? void 0 : _b.startTimestamp
            };
            this.setAgentCallMonitoringStateDetails(agentCallMonitoringState);
            const supervisorName = (_c = participants[bargedSupervisorId]) === null || _c === void 0 ? void 0 : _c.name;
            triggerBargeInNotification(supervisorName);
        }
    }
    updatechatConferenceData(data) {
        this.chatConferenceData = {
            interactionId: data.interactionId,
            destAgentId: data.destAgentId,
            initiateConferenceChat: data.initiateConferenceChat,
            agentId: data.agentId
        };
    }
    updateWarnOnNavigation(data) {
        this.navigationWarning[data.key] = data.value;
    }
    resetWarningOnNavigation() {
        this.navigationWarning = {
            cadVariables: false,
            chatMessages: false,
            wrapUpReason: false
        };
    }
    setMonitoringOwnerName(ownerName) {
        this.monitoringOwnerName = ownerName;
    }
    setIsMonitoringNotEndedManually(isEnded) {
        this.isMonitoringNotEndedManually = isEnded;
    }
    setIsWebCallMuted(muteValue) {
        this.isWebCallMuted = muteValue !== undefined ? muteValue : !this.isWebCallMuted;
    }
    get checkBeforeNavigation() {
        return Object.keys(toJS(this.navigationWarning)).filter((item) => toJS(this.navigationWarning)[item] === true)
            .length > 0
            ? true
            : false;
    }
}
__decorate([
    observable
], ModuleAgentContact.prototype, "taskMap", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "EpDnConsultDestinationAgentJoined", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "lastCompletedTask", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "selectedTaskId", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "newOfferPopoverCount", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "interactionIds", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "openCADSection", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "chatConferenceData", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "checkNewReplies", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "showNewReply", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "showArrow", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "copyTaskMap", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "navigationWarning", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "interactionControlDimension", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "interactionControlCAD", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "monitoringOwnerName", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "isMonitoringNotEndedManually", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "isWebCallMuted", void 0);
__decorate([
    observable
], ModuleAgentContact.prototype, "agentCallMonitoringState", void 0);
__decorate([
    computed
], ModuleAgentContact.prototype, "getEngagedStatus", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "getIsAgentOnActiveCall", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "isTelephoneOrMidcall", null);
__decorate([
    action
], ModuleAgentContact.prototype, "checkReplies", null);
__decorate([
    action
], ModuleAgentContact.prototype, "isTaskRinging", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "showNewReplyButton", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "showArrowUp", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "contact", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "shouldDisableOutdialForExclusive", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "taskSelected", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "isNotTelephoneOrMidcall", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "callPersistence", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "isMediaTypeTelePhony", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "taskMapForPopover", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "taskArray", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "isActiveCall", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "newPopoverCount", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "isDefinedIvrTranscript", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "getCallGuideProps", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "getCADSectionOpenStatus", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "getMainCadSectionStatus", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "getcheckReplies", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "getConsultCadSectionStatus", null);
__decorate([
    action
], ModuleAgentContact.prototype, "setInteractionIds", null);
__decorate([
    action
], ModuleAgentContact.prototype, "removeAutoCompleteTasks", null);
__decorate([
    action
], ModuleAgentContact.prototype, "selectedTaskChange", null);
__decorate([
    action
], ModuleAgentContact.prototype, "rerenderTaskMap", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateLastCompletedTask", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateCadSectionDimension", null);
__decorate([
    action
], ModuleAgentContact.prototype, "setInteractionControlDimensions", null);
__decorate([
    action
], ModuleAgentContact.prototype, "toggleInteractionControlCADSection", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateRecordingStatus", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateRecordingProps", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateShowNewReply", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateShowArrowUp", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateHeldUnheldProps", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateHeldUnheldPropsMonitoring", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateBargeInProps", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "getAgentCallMonitoringStateDetails", null);
__decorate([
    action
], ModuleAgentContact.prototype, "setAgentCallMonitoringStateDetails", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateAgentCallMonitoringStateDetails", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateInteractionAndMediaObjectForMonitorStateChangeEvent", null);
__decorate([
    action
], ModuleAgentContact.prototype, "removeSupervisorFromInteractionAndMediaObject", null);
__decorate([
    action
], ModuleAgentContact.prototype, "handleConfParticipantJoined", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateCadVariable", null);
__decorate([
    action
], ModuleAgentContact.prototype, "selectNextTask", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateTask", null);
__decorate([
    action
], ModuleAgentContact.prototype, "deleteAndSelectNextTask", null);
__decorate([
    action
], ModuleAgentContact.prototype, "deleteTask", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateForCTQ", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateForCTQFailure", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateForConsultFailed", null);
__decorate([
    action
], ModuleAgentContact.prototype, "handleAgentConsultFailed", null);
__decorate([
    action
], ModuleAgentContact.prototype, "selectAcceptedTask", null);
__decorate([
    action
], ModuleAgentContact.prototype, "resetInteractionControlDimensions", null);
__decorate([
    action
], ModuleAgentContact.prototype, "handleConsultUpdate", null);
__decorate([
    action
], ModuleAgentContact.prototype, "handleConfParticipantLeft", null);
__decorate([
    action
], ModuleAgentContact.prototype, "handleContactOwnerChanged", null);
__decorate([
    action
], ModuleAgentContact.prototype, "handleConsulting", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updatePopoverCount", null);
__decorate([
    action
], ModuleAgentContact.prototype, "selectTaskOnHomeRoute", null);
__decorate([
    action
], ModuleAgentContact.prototype, "handleContactCleanup", null);
__decorate([
    action
], ModuleAgentContact.prototype, "handleAbruptMonitoringEnd", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updatechatConferenceData", null);
__decorate([
    action
], ModuleAgentContact.prototype, "updateWarnOnNavigation", null);
__decorate([
    action
], ModuleAgentContact.prototype, "resetWarningOnNavigation", null);
__decorate([
    action
], ModuleAgentContact.prototype, "setMonitoringOwnerName", null);
__decorate([
    action
], ModuleAgentContact.prototype, "setIsMonitoringNotEndedManually", null);
__decorate([
    action
], ModuleAgentContact.prototype, "setIsWebCallMuted", null);
__decorate([
    computed
], ModuleAgentContact.prototype, "checkBeforeNavigation", null);
export const agentContact = new ModuleAgentContact();
// JS DOC Comments - Type Definitions
/**
 * @typedef  {Object} ChatConferenceData
 * @summary Store.AgentContact.chatConferenceData
 * @description Chat Conference Data
 * @property {String} interactionId="" Unique identifier of the user interaction.
 * @property {String} destAgentId="" Unique identifier of the destination/secondary agent.
 * @property {Boolean} initiateConferenceChat=false Determines whether chat conference can be initiated or not.
 * @property {String} agentId="" Unique identifier of the agent.
 */
/**
 * @typedef  {Map<TaskID, Task>} TaskMap
 * @summary The full list of tasks that are assigned to an agent at a given time.
 * @property {String} TaskId Unique identifier of the task.
 * @property {Service_Aqm_Contact_AgentContact_Data} Task Details of the task. [Service.Aqm.Contact.AgentContact.data]
 */
/**
 * @typedef  {Object} agentContact
 * @summary $STORE.agentContact
 * @property {TaskMap} taskMap The full list of tasks that are assigned to an agent at a given time.
 * @property {String} lastCompletedTask="" Unique Identifier for the last wrapped up task.
 * @property {String} selectedTaskId="" Unique Identifier for the selected task.
 * @property {Array<String>}interactionIds=[]] List of unique identifiers of the user interactions.
 * @property {ChatConferenceData} chatConferenceData Chat conference data. [Store.AgentContact.chatConferenceData]
 * @property {Object | null} taskSelected Gives the user interaction details about the selected task.
 */
